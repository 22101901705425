import { render, staticRenderFns } from "./ViewGruppo.vue?vue&type=template&id=31402c96&scoped=true&"
import script from "./ViewGruppo.vue?vue&type=script&scoped=true&lang=js&"
export * from "./ViewGruppo.vue?vue&type=script&scoped=true&lang=js&"
import style0 from "./ViewGruppo.vue?vue&type=style&index=0&id=31402c96&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31402c96",
  null
  
)

export default component.exports