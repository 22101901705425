<template slot="tag" slot-scope="props">
    <div class="container-fluid">
        <div id="task" class="corpo">
            <div class="row">
                <div class="offset-md-1 col-md-10 offset-md-1">
                    <h5>Crea un Task</h5>
                </div>
                <div class="offset-md-1 col-md-10 offset-md-1">
                    <div class="input-field col s12 ">
                        <i class="mdi mdi-calendar prefix primary-text"></i>
                        <input  type="text" v-model="nomeTask">
                        <label >Nome Task</label>
                    </div>
               
                    <div class="input-field col s12">
                        <i class="mdi mdi-calendar-text prefix primary-text"></i>
                        <textarea  class="materialize-textarea" v-model="descTask">

                        </textarea>
                        <label >Descrizione Task</label>
                    </div>
                    <div class="col s12 select select1">
                        <p>Priorità Task:</p>
                        <multiselect style="width: 89%;"
                            v-model="valuePriorita"
                            :options="optionsPriorita"
                            :multiple="false" 
                            :close-on-select="true"
                            label="name"
                            track-by="name"
                            open-direction="bottom"
                            placeholder="Priorità">
                        </multiselect>
                    </div>
                    <div class="input-field col s12">
                        <i class="mdi mdi-clock-start prefix"></i>
                        <input type="date" id="birth" class="" name="birth" data-date-format="DD-MMMM-YYYY" v-model="dataScadenza">
                        <label class="active">Scadenza Task</label>
                    </div>
                    <div class="col s12 select" v-show="!invitaTutti">
                        <p>Assegna task:</p>
                        <multiselect 
                            v-model="valueUtenti" 
                            :options="optionsUtenti" 
                            :multiple="true" 
                            :close-on-select="true"
                            :max-height="150"
                            :height="0"
                            open-direction="bottom"
                            label="name" 
                            track-by="name"
                            placeholder="Utenti">
                        </multiselect>
                    </div>
                    <div class="col" v-show="daGruppo">
                      <p>
                        <input type="checkbox" v-model="invitaTutti" class="filled-in" id="filled-in-box" />
                        <label for="filled-in-box">Invita tutti</label>
                      </p>
                    </div>
                    <div class="col s12 sottogruppi">
                        <p>Task di:</p>
                        <div :class="'waves-effect waves-light btn ' + (sottogruppo=='personal' ? 'selezionato': 'noselezionato') "  @click="sottogruppo='personal'">
                            <i :class="'mdi mdi-heart-outline left ' + (sottogruppo=='personal' ? 'selezionatoi': 'noselezionatoi')"></i>personal
                        </div>
                        <div :class="'waves-effect waves-light btn '+(sottogruppo=='business' ? 'selezionato': 'noselezionato') " @click="sottogruppo='business'">
                            <i :class="'mdi mdi-briefcase-outline left ' + (sottogruppo=='business' ? 'selezionatoi': 'noselezionatoi')"></i>business
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CreaNavBar :pagina="posAttuale" :paginaPrecedente="posPrecedente" @metodo="creaTask"/>
    </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios'
import swal from 'sweetalert'
import router from '../router'
import store from '../store'
import CreaNavBar from '../Components/CreaNavBar.vue'
import Multiselect from 'vue-multiselect'
  
export default {
  name: "CreaTask",
  components: { Multiselect,CreaNavBar },
  data: function () {
        return {
            
            info:null,
            posAttuale:"",
            posPrecedente:"",
            nomeTask:"",
            descTask:"",
            dataScadenza:moment().format('YYYY-MM-DD'),
            sottogruppo:"personal",
            valuePriorita: {
                name: 'Tempo Reale',
                value: 5 
            },
            optionsPriorita: [
                {
                    name: 'Tempo Reale',
                    value: 5 
                },{
                    name: 'Alta',
                    value: 4
                },{
                    name: 'Superiore al normale',
                    value: 3
                },{
                    name: 'Normale',
                    value: 2
                },{
                    name: 'Inferiore al normale',
                    value: 1
                },{
                    name: 'Basso',
                    value: 0
                }],
            valueUtenti: false,
            invitaTutti: false,
            daGruppo: false,
            optionsUtenti: []
        };
    },
    beforeMount(){
        if (this.$route.params.idGruppo) {
          this.daGruppo = this.$route.params.idGruppo
        }
        axios.post("user/getusers" + (this.daGruppo ? '?idGruppo=' + this.daGruppo : '?withme=1'), {
            token: store.getters.token
        })
        .then((response) => {
            this.optionsUtenti = response.data.utenti.map((elemento) => {
                return {
                    name: elemento.first_name + " " + elemento.last_name,
                    value: elemento.id
                }
            })
        })
        .catch(function (error) {
            console.log (error)
        })
        document.body.style.backgroundColor = "#f5f5f5";
        document.body.style.color = "#455A64";
        this.posAttuale= localStorage.posAttuale
        this.posPrecedente= localStorage.posPrecedente
    },
    methods:{
        goTo: function (url){
            router.push(url);
        },
        creaTask: function() {

            if(this.nomeTask.length==0 || this.descTask.length==0 || (this.valueUtenti.length==0 && this.daGruppo == false)){
                swal("Attenzione","Inserisci tutti i campi", "warning");
            }else{
                var link = "task/create"
                var datiInviare = {
                    token: store.getters.token,
                    title: this.nomeTask,
                    descrizione: this.descTask,
                    scadenza: this.dataScadenza,
                    type: this.sottogruppo === 'personal' ? 0 : 1,
                    priorita: this.valuePriorita.value
                }
                if (this.invitaTutti !== false) {
                    datiInviare.idGroup = this.daGruppo
                } else {
                    datiInviare.assignTo = this.valueUtenti.value
                }
                axios.post(link,datiInviare)
                .then((response) => {
                    if (response.data.status == 'OK') {
                        if (this.daGruppo !== false) {
                            this.goTo('/viewGruppo/' + this.daGruppo)
                        } else {
                            this.goTo('/task')
                        }
                    } else {
                        swal("Attenzione",response.data.message, "warning");
                    }
                })
                .catch(function (error) {
                console.log(error);
                })
            }
        }
    }
};
</script>

<style scoped>

    .corpo {
        margin-top: 7%;
    }

    .corpo h5{
        color: rgb(252, 166, 73);
        text-transform:uppercase;
        font-size: 19px;
        line-height: 31px;
        font-weight: 700;
        margin-right: 1%;
        margin-top: 2%;
    }

    p{
    color: rgb(252, 166, 73);
        font-size: 17px;
        line-height: 31px;
        font-weight: 500;
        margin-top: 2%;
    }

    input{
        color: rgb(252, 166, 73);
        
    }

    i,i:hover,i:active,i:focus{
        color: rgb(252, 166, 73);
    }

    .input-field {
        margin-bottom: 1%;
    }

    .input-field label {
        color: rgb(252, 166, 73);
        margin-top: 9px;
    }

    input,input:focus,input:active,input:hover{
        border-bottom: 1px solid rgb(252, 166, 73);
    }

    .input-field label, .input-field label:hover, .input-field label:focus, .input-field label:active  {
        font-size: 15px;
        color: rgb(252, 166, 73);
    }

    [type="textarea"]+label{
        color: rgb(252, 166, 73);
        margin-top: 15px;
    }

    input:focus{
    border-bottom: 1px solid rgb(252, 166, 73) !important;
    box-shadow: 0 1px 0 0 rgb(252, 166, 73) !important;
    }

    /* label focus color */
    .input-field input:focus + label {
    color: rgb(252, 166, 73) !important;
    }

    /* label focus color */
    .input-field input + label {
        color: rgb(252, 166, 73) !important;
    }

    /* label underline focus color */
    .input-field input:focus {
        border-bottom: 1px solid rgb(252, 166, 73);
        box-shadow: 0 1px 0 0 rgb(252, 166, 73);
    }

    /* icon prefix focus color */
    .input-field .prefix.active {
        color: rgb(252, 166, 73);
    }

    /* valid color */
    .input-field input.valid {
        border-bottom: 1px solid rgb(252, 166, 73);
        box-shadow: 0 1px 0 0 rgb(252, 166, 73);
    }

    /* invalid color */
    .input-field input.invalid {
        border-bottom: 1px solid rgb(252, 166, 73);
        box-shadow: 0 1px 0 0 rgb(252, 166, 73);
    }


    textarea.materialize-textarea,textarea.materialize-textarea:focus,textarea.materialize-textarea:active,
    textarea.materialize-textarea:hover {
        border-bottom: 1px solid rgb(252, 166, 73);
        color: rgb(252, 166, 73) !important;
        padding: 4px;
        font-size: 15px;
        margin-top: 21px;
        min-height:0px;
    }

    textarea.materialize-textarea:focus,textarea.materialize-textarea:active,
    textarea.materialize-textarea:hover {
        box-shadow: 0 1px 0 0 rgb(252, 166, 73);
    }

    .input-field textarea.materialize-textarea:focus + label {
        color: rgb(252, 166, 73) !important;
    }

    .input-field .prefix {
        margin-top: 17px;
        font-size: 23px;
    }

    .btn,.btn:active,.btn:hover,.btn:focus{
        background-color: rgb(252, 166, 73);
        color: #fff;
        margin-top: 6%;
    }

    .select {
        position: relative;
        display: flex;
        z-index: 3;
    }

    .select span,.select span:active,.select span:hover,.elect span:focus {
        background-color: rgb(252, 166, 73);
    }

    .select1 {
        z-index: 4;
    }

    .sottogruppi{
        margin-bottom: 5%;
    }

    .sottogruppi i{
        color: #fff;
        font-size: 17px;
    }

    .sottogruppi .btn{
        margin-right:5px ;
        margin-top:0;
    }

    .noselezionato,.noselezionato:hover,.noselezionato:active,.noselezionato:focus{
        background-color: #fff;
        color: grey;
    }

    .noselezionatoi{
        color: grey !important; 
    }

    .selezionato,.selezionato:focus,.selezionato:hover,.selezionato:active{
        background-color: rgb(252, 166, 73);
        color: #fff;
    }

    .selezionatoi{
        color: #fff; 
    }

    @media screen and (max-width: 1024px){
        
        .corpo{
            margin-top: 17%;
        }

        .corpo h5{
            font-size: 25px;
        }

        .input-field {
            margin-top: 8%;
        }

        .input-field .prefix {
            margin-top: 9px;
            font-size: 28px;
        }

        .input-field label, .input-field label:hover, .input-field label:focus, .input-field label:active {
            margin-top: 0px;
            font-size: 22px;
        }

        .input-field  input {
            font-size: 22px;
        }

        textarea.materialize-textarea,textarea.materialize-textarea:focus,textarea.materialize-textarea:active,
        textarea.materialize-textarea:hover {
            font-size: 22px;
        }

        p {
            font-size: 21px;
            margin-top: 3%;
        }

        .sottogruppi .btn {
            margin-top: 5px;
        }

        .btn{
            margin-top: 0px;
            font-size: 20px;
            height: 48px;
        }
        
    }

</style>
