import { render, staticRenderFns } from "./CreaEvent.vue?vue&type=template&id=eed17e3e&scoped=true&slot=tag&slot-scope=props&"
import script from "./CreaEvent.vue?vue&type=script&lang=js&"
export * from "./CreaEvent.vue?vue&type=script&lang=js&"
import style0 from "./CreaEvent.vue?vue&type=style&index=0&id=eed17e3e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eed17e3e",
  null
  
)

export default component.exports