<template>
  <div class="container">
    <div class="row">
        <div class="col-sm-12 col-12 logo">
            <img @click="goTo('/')" src="../../public/timelosophy-logo-white.png" alt="logo">
        </div>
        <div class="offset-md-1 col-md-10 offset-md-1">
          <div class="login">
            <div class="input-field">
              <input type="email" v-model="email" class="validate">
              <label for="email">Email</label>
            </div>
            <div class="input-field">
              <input type="password" v-model="psw" class="validate">
              <label for="Password">Password</label>
            </div>
            <div class="input-field">
              <input type="password" v-model="pswConf" class="validate">
              <label for="repeatPassword">Ripeti Password</label>
            </div>  
            <center>
              <p>
                <input type="checkbox" v-model="accetto" class="filled-in" id="filled-in-box" />
                <label for="filled-in-box">Accetto trattamento dei dati personali</label>
              </p>
            </center>
          </div>
        </div>
        <div class="offset-md-4 col-md-4 offset-md-4">
          <div class="link">
            <center>
              <router-link to="/">Hai già un account?</router-link>
            </center>
          </div>
        </div>
        <div class="offset-md-4 col-md-4 offset-md-4">
            <div class="bottone">
            <center>
                <button type="button" @click="controlladati()">
                    <i class="mdi mdi-check"></i>
                </button>
            </center>
            </div>
        </div>
    </div>
  </div>
</template>

<script scoped>
import axios from 'axios'
import router from '../router'
import swal from 'sweetalert'
import utils from '../utils';
export default {
  name: "Registra",
  data: function () {
    return {
      email:   "",
      psw:     "",
      pswConf: "",
      accetto: false
    };
  },
  beforeMount(){
    utils.setBgColor("rgb(255, 76, 78)", "#fff");
  },
  methods: {
    controlladati () {

      if (this.email && this.psw && this.pswConf && this.accetto == true) {

        if (this.psw == this.pswConf) {
          axios.post('user/registra',
            {
              email: this.email,
              psw:   this.psw
            }
          )
          .then((response) => {
            let info = response.data
            if(info.status == "KO") {
              swal("Attenzione", info.message, "error");
            } else {
              swal("Complimenti", "Registrazione completata con successo, entra per continuare", "success");
              this.goTo('/');
            }
          }).catch(function (error) {
            console.log(error);
          })

          return true;
        }

        swal("Attenzione","Le password non coincidono", "warning");
      } else {
        swal("Attenzione","Inserisci tutti i campi", "warning");
      }
    },
    goTo (url) {
      router.push(url);
    }
  }
};
</script>

<style scoped>
  .logo{
    text-align: -webkit-center;
    margin-top:4%;
  }
  .login{
    font-size: 15px;
    margin-top: 12%;
  }
  .input-field label, .input-field label:hover, .input-field label:focus, .input-field label:active{
      font-size: 15px;
      color: #fff;
  }
  input, input:hover, input:active{
    margin-top:2%;
    color: #fff;
    background-color : transparent; 
    box-shadow: none;
    outline: none;
    border-bottom: 0 1px 0 0 #f44336 !important;
    box-shadow: 0 1px 0 0 #f44336 !important;
    margin-left: 12px !important;
  }
  [type="checkbox"]+label{
      color: #fff;
      margin-top: 15px;
  }
  input:focus{
    border-bottom: 1px solid #fff !important;
    box-shadow: 0 1px 0 0 #fff !important;
  }
  /* label focus color */
  .input-field input:focus + label {
    color: #fff !important;
  }
  /* label focus color */
  .input-field input + label{
    color: #fff !important;
  }
  /* label underline focus color */
  .input-field input:focus{
    border-bottom: 1px solid #fff;
    box-shadow: 0 1px 0 0 #fff;
  }
  /* icon prefix focus color */
  .input-field .prefix.active{
    color: #fff;
  }
  /* valid color */
  .input-field input.valid{
    border-bottom: 1px solid #fff;
    box-shadow: 0 1px 0 0 #fff;
  }
  /* invalid color */
  .input-field input.invalid{
    border-bottom: 1px solid #fff;
    box-shadow: 0 1px 0 0 #fff;
  }
  .filled-in:checked+label:after{
      top: 0px;
      width: 20px;
      height: 20px;
      border: 2px solid #f44336;
      background-color: #f44336;
      z-index: 0;
  }
  .bottone{
    text-align: center;
  }
  button,button:active,button:hover,button:focus {
      margin-top: 40px;
      background-color: #ff5555;
      position: relative;
      height: 56px;
      width: 56px;
      border: 8px solid aliceblue;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      top: -12px;
      z-index: 98;
  }
  button i{
    margin-left: 4px;
    font-size: 20px;
  }

  a {
    text-decoration: none;
    color: #fff;
  }

  @media screen and (max-width: 1024px) {
    .login{
        font-size: 15px;
        margin-top: 34%;
    }
    
    .logo{
      margin-top: 15%;
    }
    .logo img{
      height: 33px;
    }
    .input-field{
      margin-bottom: 10%
    }
    .input-field label, .input-field label:hover, .input-field label:focus, .input-field label:active{
        font-size: 27px;
    }
    .input-field  input{
      font-size: 28px;
    }
    [type="checkbox"]+label{
      font-size: 26px;
    }
    .link{
      font-size: 27px;
      margin-top: 25%;
    }
    button, button:active, button:hover, button:focus{
      margin-top: 12%;
      height: 70px;
      width: 70px;
    }
    button i{
      margin-left: 6px;
      font-size: 30px;
    }
  }
  @media screen and (max-width: 768px) {
    .logo{
      margin-top:10%;
      height: 33px;
    }
    .logo img{
      height: 33px;
    }
    .input-field{
      margin-bottom: 10%
    }
    .input-field label, .input-field label:hover, .input-field label:focus, .input-field label:active
    {
        font-size: 24px;
    }
    .input-field  input{
      font-size: 25px;
    }
    .link{
      font-size: 25px;
      margin-top: 65px;
    }
    [type="checkbox"]+label{
      font-size: 24px;
    }
    button, button:active, button:hover, button:focus{
      margin-top: 13%;
      height: 65px;
      width: 65px;
    }
    button i{
      margin-left: 5px;
      font-size: 28px;
    }
  }
</style>
