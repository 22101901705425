<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-12 logo">
          <img src="../assets/logo.png" alt="logo">
      </div>
      <div class="offset-md-2 col-md-8 offset-md-2 col-sm-12">
        <div class="login">
          <div class="input-field">
            <input type="email" v-model="email" class="validate">
            <label for="email">Email</label>
          </div>
          <a data-tooltip="errore">{{messaggio}}</a>
          <div class="input-field">
            <input :type="showPassword? 'text' : 'password'" v-model="psw" class="validate">
            <label for="Password">Password</label>
            <i class="material-icons" @click="togglePassword()">remove_red_eye</i>
          </div>
        </div>
      </div>
      <div class="offset-md-4 col-md-4 offset-md-4">
        <div class="link">
          <center>
            <router-link to="/registra">Crea un account</router-link>
          </center>
        </div>
      </div>
      <div class="offset-md-4 col-md-4 offset-md-4">
          <div class="bottone">
            <center>
                <button type="button" @click="controlladati()">
                    <i class="mdi mdi-check"></i>
                </button>
            </center>
          </div>
      </div>  
    </div>
  </div>
</template>

<script scoped>
import axios from 'axios'
import router from '../router'
import store from '../store'
import swal from 'sweetalert'
import utils from '../utils'
import ciao from '../utilities'

export default {
  name: "Login",
  data: function () {
    return {
      email:     "",
      psw:       "",
      messaggio: "",
      showPassword: false
    };
  },
  beforeMount () {
    utils.setBgColor("rgb(255, 76, 78)", "#fff");
    if (store.state.loggedIn != false) {
      this.goTo("/group")
    }
    console.log(ciao)
  },
  methods:{
    controlladati () {
      if (this.email && this.psw) {
        var link = "user/login"
        axios.post(link, {
          email: this.email,
          psw:   this.psw
        })
        .then(response => {
          let info = response.data
          if (info.token != null) {
            store.commit('login', {
              token:    info.token,
              loggedIn: true
            });

            router.push('/group');
            return true
          }

          swal("Errore",info.message, "error");
        })
        .catch(function (error) {
          console.log(error);
        });

        return true;
      }

      swal("Attenzione","Inserisci tutti i campi", "warning");
    },
    goTo: function (url){
      router.push(url);
    },
    togglePassword () {
      this.showPassword = !this.showPassword
    }
  }
};
</script>

<style scoped>
  .logo{
    text-align: -webkit-center;
    margin-top:4%;
  }
  .login{
    font-size: 15px;
    margin-top: 20%;
  }
  .input-field label, .input-field label:hover, .input-field label:focus, .input-field label:active {
    font-size: 15px;
    color: #fff;
  }
  input, input:hover,input:active{
    margin-top:2%;
    color: #fff;
    background-color : transparent; 
    box-shadow: none;
    outline: none;
    border-bottom: 0 1px 0 0 #f44336 !important;
    box-shadow: 0 1px 0 0 #f44336 !important;
    margin-left: 12px !important;
  }
  input:focus{
    border-bottom: 1px solid #fff !important;
    box-shadow: 0 1px 0 0 #fff !important;
  }
  .input-field input:focus + label{
    color: #fff;
  }
  .input-field input:focus{
    border-bottom: 1px solid #fff;
    box-shadow: 0 1px 0 0 #fff;
  }
  .input-field .prefix.active{
    color: #fff;
  }
  .input-field input.valid{
    border-bottom: 1px solid #fff;
    box-shadow: 0 1px 0 0 #fff;
  }
  .input-field input.invalid{
    border-bottom: 1px solid #fff;
    box-shadow: 0 1px 0 0 #fff;
  }
 
  .link{
    margin-top: 18%;
  }
  .link a{
    text-align: center;
    cursor:pointer;
  }
  button i{
    margin-left: 4px;
    font-size: 20px;
  }
  .bottone{
    text-align: center;
  }
  button, button:active, button:hover, button:focus{
      margin-top: 7%;
      background-color: #ff5555;
      position: relative;
      height: 56px;
      width: 56px;
      border: 8px solid aliceblue;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      top: -12px;
      z-index: 98;
      outline: none;
  }

  a {
    text-decoration: none;
    color: #fff;
  }

  .material-icons {
    color: #000;
    position: absolute;
    top: 50%;
    right: 10px;
    cursor: pointer;
    transform: translate(0, -50%);
  }

  input {
    padding-left: 10px;
    box-sizing: border-box;
  }

  @media screen and (max-width: 1024px) {
    .logo{
      margin-top: 15%;
    }
    .logo img{
      height: 33px;
    }
    .login{
        font-size: 15px;
        margin-top: 50%;
    }
    .input-field{
      margin-bottom: 10%
    }
    .input-field label, .input-field label:hover, .input-field label:focus, .input-field label:active{
        font-size: 27px;
    }
    .input-field  input{
      font-size: 28px;
    }
    .link{
      font-size: 27px;
      margin-top: 25%;
    }
    button, button:focus, button:hover, button:active{
      margin-top: 12%;
      height: 70px;
      width: 70px;
    }
    button i{
      margin-left: 6px;
      font-size: 30px;
    }
  }
  @media screen and (max-width: 768px) {
    .logo{
      margin-top: 17%;
    }
    .logo img{
      height: 22px;
      width: auto;
    }
    .input-field{
      margin-bottom: 10%
    }
    .input-field label, .input-field label:hover, .input-field label:focus, .input-field label:active{
        font-size: 24px;
    }
    .input-field  input{
      font-size: 25px;
    }
    .link{
      font-size: 25px;
      margin-top: 65px;
    }
    button, button:active, button:hover, button:focus{
      margin-top: 13%;
      height: 65px;
      width: 65px;
    }
    button i{
      margin-left: 5px;
      font-size: 28px;
    }

    input, .input-field {
      max-width: 290px;
    }
  }
</style>
