<template>
  <div id="app">
    <div :class="['modal ', openModalNotifica ? 'open notifica-modal' : '']">
        <div class="modal-header">
            <h4>Notifica del {{datiModal.creato_il}}</h4>
        </div>
        <div class="modal-content">
            <p>{{datiModal.testo}}</p>
            <p v-if="['crea-gruppo', 'modifica-gruppo', 'crea-evento', 'modifica-evento', 'crea-task'].includes(datiModal.evento)" class="ridirect" @click="ridireziona(datiModal.id_riferimento, datiModal.evento)">
               Vai a ->
            </p>
        </div>
        <div class="modal-footer">
            <a class="btn btn-flat waves-effect waves-green" @click="openModalNotifica = false">Chiudi</a>
        </div>
    </div>
    <div class="container-fluid" v-show="$store.state.loggedIn">
        <div v-show="mostraNotifiche" class="modal-notifiche">
            <ul>
                <li v-for="notifica in notifiche" :key="notifica.id">
                    <a :class="'notifica-in-elenco ' + (notifica.letto == '0' ? 'grassetto' : '')" @click="ridireziona(notifica.id_riferimento, notifica.evento, notifica.id)"> {{notifica.testo}} </a>
                </li>
            </ul>
        </div>
        <nav id="navInAlto" :class="classeSfondo">
            <div class="nav-wrapper">
                <a @click="goTo('/group')" class="logo">
                    <img src="./assets/logo.png" alt="logo">
                </a>

      
                <ul id="nav-mobile" class="right">
                    <li>
                      <input type="text" v-show="['/group', '/task', '/chat'].indexOf($router.currentRoute.path) !== -1" placeholder="Cerca" class="ricerca" @keyup="emitSearch" v-model="search" />
                    </li>
                    <li>
                        <a @click="tendina=!tendina" v-show="$router.currentRoute.path === '/group'">
                            <i class="mdi mdi-filter-outline"></i>
                        </a>
                    </li>
                    <li style="position: relative">
                        <a @click="mostraNotifiche = !mostraNotifiche">
                            <i :class="'mdi mdi-bell' + (notifiche.length > 0 ? '' : '-outline') + ( notifiche.length > 0 ? ' dropdown-trigger' : '')"></i>
                            <span class="pallino-notifiche"></span>
                        </a>
                    </li>
                    <li>
                        <a @click="goTo('/profilo')">
                            <i class="mdi mdi-cog"></i>
                        </a>
                    </li>
                    <li>
                        <a @click="logout()">
                            <i class="mdi mdi-exit-to-app"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
        <div class="menu-filter z-depth-1" v-show="tendina" :class="classeColore">
            <div id="my-ftl" @click="emitFilter('me')">
                <div class="icona">
                    <i class="mdi mdi mdi-google-circles-extended"></i>
                </div>
                <div class="paragrafo">
                    <p>Creati da me</p>
                </div>
            </div>
            <div @click="emitFilter('other')">
                <div class="icona">
                    <i class="mdi mdi-google-circles-extended"></i>
                </div>
                <div class="paragrafo">
                    <p>Di altri</p>
                </div>
            </div>
            <div id="reset-ftl" @click="emitFilter('all')">
                <i class="mdi mdi-backup-restore"></i>
            </div>
            <div id="business-ftl" @click="emitFilter('business')">
                <div class="icona">
                    <i class="mdi mdi-tag-outline"></i>
                </div>
                <div class="paragrafo">
                    <p>Business</p>
                </div>
            </div>
            <div id="private-ftl" @click="emitFilter('personal')">
                <div class="icona">
                    <i class="mdi mdi-tag-outline"></i>
                </div>
                <div class="paragrafo">
                    <p>Personale</p>
                </div>
            </div>
            <div @click="tendina=!tendina">
                <i class="mdi mdi-window-close"></i>
            </div>
        </div>
    </div>
    <div id="nav">
      <router-link to="/"></router-link>
    </div>
    <router-view />
    <NavBarBassa v-bind:pagina="posAttuale" />
    
  </div>
</template>
<script>
import router from './router'
import store from './store'
import NavBarBassa from './Components/NavBarBassa.vue'
import axios from 'axios'
//import M from 'materialize-css'

export default {
  name: "App",
  components:{NavBarBassa},
  data: function () {
      return {
          open: true,
          nascondimenu: false,
          posAttuale: "",
          posPrecedente: "",
          classeSfondo: false,
          classeColore: false,
          tendina: false,
          visualizzaCrea: ['creaEvent','creaSondaggio','creaGruppo','creaTask','showTask','showEvent'],
          bodyMarginEvent: ['event','modifica','profilo'],
          createEvent: ['event','modifica','profilo'],
          createPoll: ['event','task','modifica','profilo'],
          createGroup: ['group','modifica','profilo'],
          blu: ['chat','viewChat'],
          giallo: ['task','showTask','creaTask'],
          rosso: ['group','profilo','modifica','creaGruppo','viewGruppo'],
          verde: ['event','sondaggi','creaEvent','creaSondaggio','showEvent'],
          notifiche: [],
          mostraNotifiche: false,
          timeoutNotifiche: false,
          openModalNotifica: false,
          datiModal: false,
          search: ''
      };
  },
  
  watch: {
      mostraNotifiche: function (val) {
        console.log(val);
      },
      $route: function (to, from) {
        this.posAttuale=to.name
        this.posPrecedente=from.name
        console.log("pagina in app: ",this.posAttuale)
        console.log("from: ",this.posPrecedente)
       
        switch(true){
            case this.blu.includes(this.posAttuale):
                this.classeSfondo="bluSfondo"
                this.classeColore="blu"
                break
            case this.giallo.includes(this.posAttuale):
                this.classeSfondo="gialloSfondo"
                this.classeColore="giallo"
                break
            case this.verde.includes(this.posAttuale):
                this.classeSfondo="verdeSfondo"
                this.classeColore="verde"
                break
            case this.rosso.includes(this.posAttuale):
                this.classeSfondo="rossoSfondo"
                this.classeColore="rosso"
                break
            default:
                this.visibile=false
        }
        
         localStorage.setItem("classeSfondo", this.classeSfondo)
         localStorage.setItem("classeColore", this.classeColore)
         localStorage.setItem("posAttuale", this.posAttuale)
         localStorage.setItem("posPrecedente", this.posPrecedente)
         //console.log("storage ",localStorage)
      }
  },
  created () {
    let that = this
    this.timeoutNotifiche = setInterval(function (){
        that.openModalNotifica = false
        that.datiModal = false
        var link = store.getters.baseUrl + "notification/get"
        axios.post(link,{
            token: store.getters.token,
            length: 10
        })
        .then((response) => {
            that.notifiche = response.data.notifications
        })
        .catch(function (error) {
            console.log(error);
        })
    }, 5000);
  },
  beforeMount(){
        //console.log("token chat : ",store.getters.token)
        if (localStorage.classeSfondo) {
            this.classeSfondo = localStorage.classeSfondo
        }
        if (localStorage.classeColore) {
            this.classeColore = localStorage.classeColore
        }
        if (localStorage.posAttuale) {
            this.posAttuale = localStorage.posAttuale
        }
  },
  methods:{
    logout: function () {
        store.commit('logout',{
            token: null,
            loggedIn: false
        })
        localStorage.removeItem("classeSfondo")
        localStorage.removeItem("classeColore")
        localStorage.removeItem("posAttuale")
        clearInterval(this.timeoutNotifiche)
        this.timeoutNotifiche = false
        router.push('/')
    },
    goTo: function (url) {
        this.open=true
        router.push(url);
    },
    apriNotifica: function (id) {
        var link = store.getters.baseUrl + "notification/get?id=" + id
        axios.post(link,{
            token: store.getters.token
        })
        .then((response) => {
            this.apriModal(response.data.notifica)
        })
        .catch(function (error) {
            console.log(error);
        })
    },
    apriModal: function (data) {
        this.openModalNotifica = true
        this.datiModal = data
    },

    async ridireziona(idRiferimento, evento, idLeggere) {

        await axios.post('notification/letto?id=' + idLeggere,{
            token: store.getters.token,
        })

        this.mostraNotifiche = false;
        let link = ''
        switch (evento) {
            case 'crea-gruppo':
            case 'modifica-gruppo':
                link = '/viewGruppo/' + idRiferimento;
                break;
            case 'crea-evento':
            case 'modifica-evento':
                link = '/showEvent/' + idRiferimento;
                break;
            case 'crea-task':
                link = '/showTask/' + idRiferimento;
                break;
        }

        router.push(link);
    },
    emitFilter (param) {
      store.commit('filter', { param })
      this.tendina = false
    },
    emitSearch () {
      store.commit('searchGroup', { q: this.search })
    },
    nascondiNotifiche: function () {
      this.mostraNotifiche = false
    }
  },

  mounted () {
    this.search = ''
  }
}
</script>
<style >
    @import"assets/style.css";

    .notifica-in-elenco {
        color: #000 !important;
        text-decoration: none !important;
    }

    .modal .ridirect:hover, .notifica-in-elenco:hover {
        cursor: pointer;
        color: #000 !important;
        text-decoration: none !important;
    }

    nav{
        display: grid;
        position: fixed;
        z-index: 3;
        border-radius: 30px;
        right: 1px;
        margin-top: 2px;
    }

    nav .nav-wrapper{
        position: relative;
        height: 100%;
        margin: 0% 4%;
    }

    nav .nav-wrapper i, nav i ,nav i:active ,nav i:focus ,nav i:hover{
        font-size: 22px;
        color: #fff;
    }

    nav .brand-logo{
        margin-top: 20px;
        display: inline-flex;
    }

    .menu-filter {
        display: grid;
        position: fixed;
        top: 0;
        left: -3px;
        width: 100%;
        color: gray;
        background-color: white;
        border-radius: 24px;
        padding: 100px 40px 40px 40px;
        grid-template-columns: auto auto 2rem;
        grid-template-rows: repeat(2, auto);
        grid-row-gap: 24px;
        z-index: 2;
        margin: 4px;
    }

    .menu-filter i{
        font-size: 22px;
    }

    .icona{
        position: absolute;
    }

    .paragrafo{
        margin: 1% 5%;
    }

    .z-depth-1{
        box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%)
    }

    .notifica-modal {
        z-index: 1003;
        display: block; 
        opacity: 1;
        transform: scaleX(1);
        top: 10%;
        height: auto !important;
        width: 90% !important;
    }

    @media screen and (max-width: 1024px){
        
        nav .nav-wrapper i, nav i, nav i:active, nav i:focus, nav i:hover {
            font-size: 28px;
        }

        .logo img{
            height: 26px;
        }
        .menu-filter i{
            font-size: 29px;
        }

        .paragrafo{
            font-size: 22px;
            margin: 1% 7%;
        }
    }

    @media screen and (max-width: 768px) {

        .menu-filter i{
            font-size: 26px;
        }

        .paragrafo{
            font-size: 19px;
            margin: 2% 10%;
        }
    }

.modal-notifiche {
  position: absolute;
  top: 66px;
  right: 50px;
  width: 300px;
  max-height: 350px;
  overflow: auto;
  background-color: #fff;
  border-bottom: 1px solid #c4c4c4;
  border-right: 1px solid #c4c4c4;
  border-left: 1px solid #c4c4c4;
  z-index: 100;
}

.modal-notifiche ul {
  margin: 0;
  padding: 0;
}

.modal-notifiche ul li {
  height: 35px;
  border-bottom: 1px solid #c4c4c4;
  padding: 0 5px;
  font-size: 13px;
  line-height: 35px;
}

.pallino-notifiche {
  background-color: black;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: 16px;
  right: 10px;
}

.grassetto {
  font-weight: bold;
}

@media (max-width: 480px) {
  .corpo {
    padding-top: 40px;
  }

  .nav-wrapper .logo {
    overflow: hidden;
    width: 30px;
    display: inline-block;
  }

  .ricerca {
    width: 120px !important;
  }

  nav .nav-wrapper i {
    font-size: 20px;
  }

  #nav-mobile a {
    padding: 0 6px;
  }

  #main-footer-menu li span {
    display: none;
  }

  .footer-menu {
    height: 60px !important;
  }

  .paragrafo p {
    font-size: 14px !important;
    padding-top: 5px;
  }

  .menu-filter .icona {
    display: none !important;
  }

  .centercover {
    margin: 0 auto !important;
    display: block;
  }

  #action-list {
    margin: 10px auto 30px auto !important;
    width: 100% !important;
  }

  .avatar img {
    display: block !important;
    margin: 0 auto !important;
  }

  .sovrapponi {
    width: 100% !important;
    margin: 0 auto 20px auto !important;
    transform: none !important;
  }

  .input-field input, .input-field input + label {
    font-size: 18px !important;
  }

  .overlay-body {
    transform: translate(-50%, -20px) !important;
    left: 50% !important;
  }

  .pallino-notifiche {
    right: 5px !important;
  }

  .card .card-content {
    margin: 0 !important;
    padding-right: 30px !important;
  }

  #chat-footer-menu {
    height: 95px !important;
  }

  #chat-footer-menu i {
    font-size: 26px !important;
  }
}
</style>
