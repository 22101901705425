<template>
  <div class="container-fluid">
    <div class="corpo">
      <div class="row">
        <div class="col-md-12">
          <h4>Chat - {{groupName}}</h4>
        </div>
        <div class="col-md-12" v-for="message in info" :key="message.id">
          <div :class="'msg ' + (message.is_me ? 'send' : '')">
            <p class="msg-info">{{message.first_name}}</p>
            <p class="date-msg">{{message.message_date.substr(0, 10)}} - {{message.message_date.substr(11, 5)}}</p>
            <p class="msg-txt">{{message.message}}</p>
          </div>
        </div>
      </div>
      <NavChat />
    </div>
  </div>
</template>

<script scoped>
import axios from 'axios'
//import router from '../router'
import store from '../store'
import NavChat from '../Components/NavChat.vue'
import utils from '../utils'

export default {
  name: "Chat",
  components: { NavChat },
  data: function () {
    return {
      info: null,
      groupName: ''
    };
  },
  beforeMount () {
    utils.setBgColor('#f5f5f5', '#455A64')
    this.fetchGroup()
    this.fetchMessages()
  },
  methods:{
    async fetchGroup () {
      try {
        let group = await axios.post('group/getgruppo?id=' + this.$route.params.id, {
          token: store.getters.token
        })

        this.groupName = group.data.gruppo.titolo
      } catch (e) {
        console.log(e)
      }
    },
    async fetchMessages ()  {
      try {
        let messages = await axios.post('chat/getsingle?id=' + this.$route.params.id, {
          token: store.getters.token
        })

        this.info = messages.data

        setTimeout(this.fetchMessages, 1500)
      } catch (e) {
        console.log(e)
      }
    }
  }

};
</script>

<style scoped>
.corpo {
  margin-top: 7%;
  margin-bottom: 10%;
}

.corpo h4 {
  color: #2C79B2;
  text-transform: capitalize;
  font-size: 22px;
  line-height: 36px;
  font-weight: 700;
  margin-left: 4%;
}

.msg {
  background: #2C79B2;
  color: white;
  border: 1px solid #2C79B2;
  border-radius: 12px;
  padding: 10px;
  margin: 12px 24px 6px 6px;
  position: relative;
}

.msg p {
  font-family: Roboto;
  font-size: 16px;
  line-height: 26px;
}

.msg.send {
  background: white;
  color: #2C79B2;
  margin: 12px 6px 6px 24px;
}

#chat-footer-menu {
  border-top: 1px solid #2C79B2;
  background-color: #f5f5f5;
  height: 120px;
}

.footer-menu {
  display: block;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.action-more-menu {
  position: fixed;
  width: 100%;
  flex-direction: row;
  bottom: 0;
  z-index: 2;
  height: 50px;
}

#chat-footer-menu ul {
  bottom: 0;
}

.footer-menu ul {
  display: inline-block;
  width: 100%;
  padding: 7px 0px 0 0px;
  margin: 0px;
}

.footer-menu li {
  width: 20%;
  display: inline-block;
  height: 100%;
  text-align: center;
}

.footer-menu li a {
  display: inline-block;
  color: #546E7A;
}

#chat-footer-menu ul li a i {
  font-size: 32px;
}

.mdi {
  color: #2C79B2;
}

#action-btn {
  background-color: #2C79B2;
  position: absolute;
  height: 56px;
  width: 56px;
  border: 8px solid aliceblue;
  border-radius: 50%;
  display: flex;
  left: 48%;
  flex-direction: column;
  justify-content: center;
  color: white;
  z-index: 98;
  top: 47px;
}

#action-btn i {
  color: #fff;
  font-size: 20px;
}

textarea {
  border: 0px;
  resize: vertical;
  outline: none;
  color: #2C79B2;
  padding: 10px;
  font-size: 16px;
}

.overlay-bodyMargin {
  top: 68%;
}

.overlay-bodyMargin i {
  margin-right: 7px;
  margin-top: 5px;
  color:#546E7A;
}

.overlay-body {
  background-color: white;
  text-align: left;
  position: fixed;
  z-index: 2;
  padding: 18px;
  width: 245px;
  border-radius: 16px;
  margin-bottom: 12px;
  left: 41%;
  padding: 24px 23px;
}

.z-depth-1 {
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%)
}

#action-list li, #action-list li a {
  color: #546E7A !important;
  font-size: 18px;
  text-decoration: none;
}

.date-msg {
  position: absolute;
  top: 10px;
  right: 10px;
}

.msg-info {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 5px;
}

.msg-txt {
  margin-bottom: 0;
}

@media screen and (max-width: 1024px) {
  .corpo{
    margin-top: 10%;
    margin-bottom: 15%;
  }
    
  .corpo h4{
    font-size: 24px;
  }

  .msg p{
    font-size: 22px;
  }

  .overlay-body {
    left: 39%;
  }

  .overlay-bodyMargin {
    top: 82%;
  }

  textarea {
    font-size: 22px;
  }

  #chat-footer-menu ul li a i{
    font-size: 40px;
  }
    
  #action-btn i {
    color: #fff;
    font-size: 32px;
  }

  #action-btn{
    height: 65px;
    width: 65px;
  }
}

@media screen and (max-width: 768px) {
  .corpo{
    margin-top: 10%;
    margin-bottom: 20%;
  }

  .msg p{
    font-size: 22px;
  }

  .overlay-bodyMargin {
    top: 76%;
  }

  .overlay-body {
    left: 36%;
  }

  textarea {
    font-size: 22px;
  }
}
</style>


