<template>
  <div class="container-fluid">
    <div class="corpo">
        <div class="row">
            <div class="offset-md-1 col-md-10">
                <div id="sondaggio-open-container">
                    <div v-for="sondaggio in this.info" :key="sondaggio.id" class="card">
                        <div class="info">
                            <h4>{{sondaggio.titolo}}</h4>
                            <p>
                                Sondaggio creato da {{sondaggio.invited_from}}<br>
                                Scade il {{sondaggio.scadenza}}
                            </p>

                            <form action="#">
                              <p v-for="option in sondaggio.options" v-bind:key="option.id">
                                <input
                                  name="group1"
                                  type="radio"
                                  :value="option.id"
                                  :id="'response' + sondaggio.id + option.id"
                                  v-model="answer[sondaggio.id]" />
                                <label :for="'response' + sondaggio.id + option.id">{{option.titolo}} <span>(<b>{{option.num_answer}}</b> risposte)</span></label>
                              </p>
                            </form>

                            <div class="waves-effect waves-light btn btn-poll" @click="answerPoll(sondaggio.id)">
                              {{ sondaggio.my_answer ? 'Cambia risposta' : 'Rispondi al sondaggio' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script scoped>
import axios from 'axios'
import router from '../router'
import store from '../store'
import swal from 'sweetalert'

export default {
  name: "Sondaggi",
  data: function () {
        return {
            info: null,
            answer: []
        };
    },
    beforeMount(){
        document.body.style.backgroundColor = "#f5f5f5";
        document.body.style.color = "#455A64";
       
        axios.post("poll/get",{
            token: store.getters.token
          })
        .then((response) => {
          this.info = response.data.polls
          response.data.polls.forEach((item) => {
            this.answer[item.id] = item.my_answer
          })
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    methods:{
        goTo: function (url){
            router.push(url);
        },

        async answerPoll (sondaggio) {
          try {
            await axios.post("poll/answer?id_poll=" + sondaggio + '&id_option=' + this.answer[sondaggio], {
                token: store.getters.token
            })

            swal("Grazie", "Hai risposto con successo al sondaggio", "success");
            setTimeout(function () {
              location.reload();
            }, 2000);
          } catch (e) {
            console.log(e)
            swal("Errore interno", "Riprova più tardi", "warning");
          }
        }
    }
};
</script>

<style scoped>

    .sondaggi {
        color: #2CB2A9;
        background: #CBCBCB;
    }

    #sondaggio-open-container {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        padding-top: 68px;
        cursor:pointer;
    }

    #sondaggio-open-container h4{
        font-size: 32px;
        font-weight: 700;
        color: #2CB2A9;
        text-transform: capitalize;
    }

    #sondaggio-open-container .info{
        float:left;
        width: 97%;
    }

    #sondaggio-open-container .icon-chat-container {
        font-size: 24px;
        color:rgb(44, 178, 169);
        float:left;
        margin-top: 2%;
    }

    #sondaggio-open-container p{
        color: #707070;
        font-size: 16px;
    }

    @media screen and (max-width: 1024px){
        
        #sondaggio-open-container h4{
            font-size: 22px;
        }


        #sondaggio-open-container p{
            font-size: 22px;
        }

    }

    @media screen and (max-width: 768px) {

        #sondaggio-open-container .info{
            width: 96%;
        }

        #sondaggio-open-container h4{
            font-size: 20px;
        }


        #sondaggio-open-container p{
            font-size: 20px;
        }
    }

    .card {
      padding: 20px;
    }

    .btn-poll {
      background-color: rgb(44, 178, 169) !important;
      margin-top: 20px;
      color: #fff;
    }
</style>


