<template>
    <div class="container-fluid">
        <div v-if="info.utente!=null" id="gropu" class="corpo">
            <div class="row">
                <div class="offset-md-2 col-md-8 offset-md-2">
                    <div class="avatar size-xxl">
                        <div class="sovrapponi" @click="chooseFiles()">
                            <img v-if="(imgProfilo == null)" src="../../public/carica.png">
                            <img v-else :src="imgProfilo">
                        </div>
                        <input id="fileUpload" type="file" @change="onFileChange" hidden/>
                    </div>

                    <center>
                      <p>Clicca sull'immagine per caricare una foto profilo</p>
                    </center>
                    
                    <div class="input-field col s12">
                        <i class="mdi mdi-account-outline prefix"></i>
                        <input placeholder="nome" v-model="info.utente.first_name" type="text" class="validate">
                        <label class="active">Nome</label>
                    </div>
                    <div class="input-field col s12">
                        <i class="mdi mdi-account-outline prefix"></i>
                        <input placeholder="cognome" v-model="info.utente.last_name" type="text" class="validate">
                        <label class="active">Cognome</label>
                    </div>
                    <div class="col s12 select ">
                        <i class="mdi mdi-account-outline prefix sesso"></i>
                        <multiselect style="width: 89%;"
                            v-model="info.utente.gender"
                            :options="optionsSesso"
                            :searchable="true"
                            :close-on-select="true"
                            :show-labels="false"
                            open-direction="bottom"
                            placeholder="Sesso">
                        </multiselect>
                    </div>
                    <div class="input-field col s12">
                        <i class="mdi mdi-calendar prefix"></i>
                        <input type="date" id="birth" class="" name="birth" data-date-format="DD-MMMM-YYYY" v-model="info.utente.birth">
                        <label class="active">Data Nascita</label>
                    </div>
                    <div class="input-field col s12">
                        <i class="mdi mdi-briefcase-outline prefix"></i>
                        <input type="text" placeholder="lavoro" v-model="info.utente.profession" class="validate">
                        <label class="active">Professione</label>
                    </div>
                    <div class="input-field col s12">
                        <i class="mdi mdi-circle-edit-outline prefix"></i>
                        <input type="text" placeholder="descriviti" v-model="info.utente.description" class="validate">
                        <label class="active">Descriviti</label>
                    </div>
                    <a id="salva" @click="modifica()" class="waves-effect waves-light btn">Salva il profilo</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script scoped>
import axios from 'axios'
import router from '../router'
import store from '../store'
import swal from 'sweetalert'
import Multiselect from 'vue-multiselect'

export default {
  name: "Modifica",
  components: { Multiselect },
  data: function () {
        return {
            imgProfilo:null,
            img:null,
            info: {},
            optionsSesso: ['Uomo','Donna','Altro']
        };
    },
    beforeMount(){
        document.body.style.backgroundColor = "#f5f5f5";
        document.body.style.color = "#455A64";
        
        axios.post("user/getinfo", { token: store.getters.token })
        .then((response) => {
          this.info = response.data
          if (response.data.utente.picture != null) {
              this.imgProfilo = store.getters.baseUrl + response.data.utente.picture
          }
        })
        .catch(function (error) {
          console.log(error);
        })

    },
    methods:{
        chooseFiles: function() {
            document.getElementById("fileUpload").click();
        },

        onFileChange(e) {
            this.img = e.target.files[0];
            this.imgProfilo = URL.createObjectURL(this.img);
        },

        modifica:function(){
            var utente = this.info.utente
            
            if(!utente.first_name || !utente.last_name || !utente.profession || !utente.description ){
                swal("Attenzione","Inserisci tutti i campi", "warning");
            } else {
                var formData = new FormData();
                formData.append("picture", this.img);
                formData.append("dati", JSON.stringify({
                  token: store.getters.token,
                  first_name: this.info.utente.first_name,
                  last_name: this.info.utente.last_name,
                  gender: this.info.utente.gender,
                  profession: this.info.utente.profession,
                  description: this.info.utente.description,
                  birth: this.info.utente.birth
                }))
                
                axios.post("user/modifica", formData)
                .then(() => {
                    this.goTo('/profilo')
                })
                .catch(function (error) {
                  console.log(error);
                })
            }
        },

        goTo: function (url){
            router.push(url);
        }
    }
};
</script>
<style scoped>
    .corpo {
        margin-top: 10%;
    }
    .avatar {
        text-align: center;
    }
    .avatar img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        border: 1px solid #e0e0e0;
        padding: 4px;
    }

    /*.sovrapponi {
        position: absolute;
        top: 29.3%;
        left: 50%;
        background-color: transparent;
        transform: translate(-50%, -50%);
    }*/
    .sovrapponi {
      position: relative;
      margin-top: 5%;
      background-color: transparent;
      transform: translate(-50%, -50%);
      margin-bottom: -5%;
      margin-left: 50%;
    }

    .sovrapponi img {
        border: 0px;
    }

    input{
        color: rgb(255, 85, 85)
    }

    .input-field label {
        color: #ff5555;
        margin-top: 9px;
    }

    input,input:focus,input:active,input:hover {
        border-bottom: 1px solid rgb(255, 85, 85);
    }

    .input-field label, .input-field label:hover, .input-field label:focus, .input-field label:active  {
        font-size: 15px;
        color: #f44336;
    }

    [type="checkbox"]+label{ 
        color: #f44336;
        margin-top: 15px;
    }

    input:focus {
        border-bottom: 1px solid #f44336 !important;
        box-shadow: 0 1px 0 0 #f44336 !important;
    }
    /* label focus color */
    .input-field input:focus + label {
        color: #f44336 !important;
    }

    /* label focus color */
    .input-field input + label {
        color: #f44336 !important;
    }
    /* label underline focus color */
    .input-field input:focus {
        border-bottom: 1px solid #f44336;
        box-shadow: 0 1px 0 0 #f44336;
    }
    /* icon prefix focus color */
    .input-field .prefix.active {
        color: #f44336;
    }

    /* valid color */
    .input-field input.valid {
        border-bottom: 1px solid #f44336;
        box-shadow: 0 1px 0 0 #f44336;
    }
    /* invalid color */
    .input-field input.invalid {
        border-bottom: 1px solid #f44336;
        box-shadow: 0 1px 0 0 #f44336;
    }

    .filled-in:checked+label:after {
        border: 2px solid #f44336;
        background-color: #f44336;
    }

    #salva{
        margin: 5% 1%;
    }

    .form-select , .form-select:active, .form-select:hover, .form-select:focus {
        margin-left: 5%;
        width: 94%;
        padding:0;
        color: rgb(255, 85, 85);
        background-color: transparent;
        border:0;
        box-shadow:none;
    }

    i{
        color: rgb(255, 85, 85);
    }

    #modifica{
        margin-top: 7%;
    }


    .btn, .btn:active, .btn:hover, .btn:focus{
        background-color: #ff5555;
        color: #fff;
    }

    .select {
        position: relative;
        display: flex;
        z-index: 2;
    }

    .sesso {
        margin-top: 13px;
        font-size: 32px;
    }

    @media screen and (max-width: 1024px){
        
        .corpo{
            margin-top: 17%;
        }

        .avatar img{ 
            height: 250px;
            width: 250px;
        }

        .sovrapponi{
            /*top: 21.3%;*/
            margin-top: 21.3%
        }

        .input-field{
            margin-top: 8%;
        }

        .input-field label, .input-field label:hover, .input-field label:focus, .input-field label:active{
            font-size: 25px;
        }

        .input-field  input{
            font-size: 25px;
        }

        .input-field input + label{
            margin-top: 14px;
        }

        .btn{
            margin-top: 0px;
            font-size: 25px;
            height: 48px;
        }
        
    }

    @media screen and (max-width: 768px) {

        .corpo{
            margin-top: 16%;
        }

        .sovrapponi{
            /*top: 22.3%;*/
            margin-top: 22.3%;
        }

        
    }
</style>
