import Vue from "vue";
import Router from "vue-router";
import utils from './utils';

// Importo le viste
import Login from "./views/Login.vue";
import Registra from "./views/Registra.vue";
import Group from "./views/Group.vue";
import Event from "./views/Event.vue";
import Task from "./views/Task.vue";
import Chat from "./views/Chat.vue";
import Profilo from "./views/Profilo.vue";
import Modifica from "./views/Modifica.vue";
import Sondaggi from "./views/Sondaggi.vue";
import CreaGruppo from "./views/CreaGruppo.vue";
import CreaTask from "./views/CreaTask.vue";
import ShowTask from "./views/ShowTask.vue";
import CreaEvent from "./views/CreaEvent.vue";
import CreaSondaggio from "./views/CreaSondaggio.vue";
import ViewChat from "./views/ViewChat.vue";
import ViewGruppo from "./views/ViewGruppo.vue";
import ShowEvent from "./views/ShowEvent.vue";

Vue.use(Router);

export default new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "login",
      component: Login
    },
    {
      path: "/registra",
      name: "registra",
      component: Registra
    },
    utils.createRoute('/group', 'group', Group),
    utils.createRoute('/event', 'event', Event),
    utils.createRoute('/task', 'task', Task),
    utils.createRoute('/chat', 'chat', Chat),
    utils.createRoute('/profilo', 'profilo', Profilo),
    utils.createRoute('/modifica', 'modifica', Modifica),
    utils.createRoute('/sondaggi', 'sondaggi', Sondaggi),
    utils.createRoute('/creaGruppo', 'creaGruppo', CreaGruppo),
    utils.createRoute('/creaGruppo/:idSottoGruppo', 'creaGruppo', CreaGruppo),
    utils.createRoute('/creaEvent/:idGruppo', 'creaEvent', CreaEvent),
    utils.createRoute('/creaTask/', 'creaTask', CreaTask),
    utils.createRoute('/creaTask/:idGruppo', 'creaTask', CreaTask),
    utils.createRoute('/showTask/:idTask', 'showTask', ShowTask),
    utils.createRoute('/showEvent/:idEvent', 'showEvent', ShowEvent),
    utils.createRoute('/creaEvent', 'creaEvent', CreaEvent),
    utils.createRoute('/creaSondaggio', 'creaSondaggio', CreaSondaggio),
    utils.createRoute('/creaSondaggio/:idSondaggio', 'creaSondaggio', CreaSondaggio),
    utils.createRoute('/viewChat/:id', 'viewChat', ViewChat),
    utils.createRoute('/viewGruppo/:idGruppo', 'viewGruppo', ViewGruppo)
  ]
});
