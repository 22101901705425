import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VCalendar from 'v-calendar';

// Use v-calendar & v-date-picker components
Vue.use(VCalendar);

Vue.config.productionTip = false

axios.interceptors.request.use(function (config) {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    return config;
});

axios.defaults.baseURL = 'https://timelosophy.zprotocol.com/';

new Vue({
  router,store,
  render: h => h(App),
}).$mount('#app')
