import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        loggedIn: false,
        token: null,
        baseUrl: 'https://timelosophy.zprotocol.com/',
        filter: '',
        searchGroup: ''
    },
    mutations: {
        login: function (state, loggedUser) {
            state.loggedIn = true;
            state.token = loggedUser.token;
        },
        logout: function (state) {
            state.loggedIn = false;
            state.token = null;
        },
        filter: function (state, param) {
          state.filter = param;
          return param;
        },
        searchGroup: function (state, param) {
          state.searchGroup = param;
          return param;
        }
    },
    getters: {
        token: state => state.token,
        baseUrl: state => state.baseUrl
    }
});
