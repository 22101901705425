<template>
  <div class="container-fluid">
    <div class="corpo">
         <div v-if="info.id" class="row">
            <div class="offset-md-1 col-md-10 offset-md-1 title">
                <p style="font-size: 32px; text-transform: uppercase;"> 
                    {{info.titolo}}
                    <i
                      v-if="isGroupAdmin"
                      @click="goTo('/creaGruppo/'+$route.params.idGruppo)" class="mdi pointer mdi-pencil primary-text right"></i>

                    <i class="mdi pointer mdi-forum-outline right" style="color: rgb(44, 121, 178)!important" @click="$router.push('/viewChat/' + info.id)"></i>
                </p>
            </div>
            <div class="offset-md-1 col-md-10 offset-md-1">
                <p> 
                    {{info.descrizione}}
                </p>

                <Collapsible>
                    <div slot="trigger">
                        <div class="customTrigger">
                            <i class="mdi mdi-shape-plus grey-text left"></i>
                            <p>Sottogruppi</p>
                        </div>
                        <div class="tendinaI title">
                            <i @click="goTo('/creaGruppo/'+$route.params.idGruppo+'s')" class="mdi mdi-plus right"></i>
                            <i @click="opengruppi=!opengruppi" v-if="opengruppi" class="mdi mdi-chevron-up right"></i>
                            <i @click="opengruppi=!opengruppi" v-if="!opengruppi" class="mdi mdi-chevron-down right"></i>
                        </div>
                    </div>
        
                    <div id="sottogruppi">
                        <div class="grid-subgroup">
                            <p v-for="sottoGruppo in info.subGroup" :key="sottoGruppo.id">
                                <a @click="goTo('/viewGruppo/'+sottoGruppo.id)" >{{sottoGruppo.titolo}}</a>
                            </p>
                        </div>
                    </div>
                </Collapsible>

                <Collapsible>
                    <div slot="trigger">
                        <div class="customTrigger">
                            <i class="mdi mdi-account-multiple-outline grey-text left"></i>
                            <p>Membri del gruppo</p>
                        </div>
                        <div class="tendinaI title">
                            <i @click="goTo('/creaGruppo/'+$route.params.idGruppo)" class="mdi mdi-plus right"></i>
                            <i @click="openmembri=!openmembri" v-if="openmembri" class="mdi mdi-chevron-up right"></i>
                            <i @click="openmembri=!openmembri" v-if="!openmembri" class="mdi mdi-chevron-down right"></i>
                        </div>
                    </div>
        
                    <div id="membri">
                        <ul class="collection">
                            <li v-for="invitati in info.user_invited" :key="invitati.id" class="collection-item">
                                <img :src="$store.getters.baseUrl + invitati.picture" class="circle avatar-img">
                                <p>{{invitati.first_name}} {{invitati.last_name}}</p>
                                <span class="right"></span>
                            </li>
                        </ul>
                    </div>
                </Collapsible>

                <Collapsible>
                    <div slot="trigger">
                        <div class="customTrigger">
                            <i class="mdi mdi-calendar grey-text left"></i>
                            <p>Prossimi eventi</p>
                        </div>
                        <div class="tendinaI title">
                            <i @click="goTo('/creaEvent/' + $route.params.idGruppo)" class="mdi mdi-plus right"></i>
                            <i @click="openeventi=!openeventi" v-if="openeventi" class="mdi mdi-chevron-up right"></i>
                            <i @click="openeventi=!openeventi" v-if="!openeventi" class="mdi mdi-chevron-down right"></i>
                        </div>
                    </div>
        
                    <div id="eventi">
                      <ul class="collection">
                        <li v-for="eventi in info.eventi" :key="eventi.id" class="collection-item card single-evento">
                          <a target="_BLANK" class="linkto-map" :href="'https://maps.google.it/maps?q=' + eventi.location"><i class="material-icons">place</i></a>
                          <a target="_BLANK" class="linkto-cal" :href="createCalendarLink(eventi)"><i class="material-icons">event</i></a>
                          <router-link :to="'/showEvent/' + eventi.id">
                            <h5>{{eventi.title}}</h5>
                            <i>{{parseDateTime(eventi.event_start)}} - {{parseDateTime(eventi.event_end)}}</i>
                            <p>{{eventi.descrizione}}</p>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                </Collapsible>

                <Collapsible>
                    <div slot="trigger">
                        <div class="customTrigger">
                            <i class="mdi mdi-format-list-checks grey-text left"></i>
                            <p>Task</p>
                        </div>
                        <div class="tendinaI title">
                            <i @click="goTo('/creaTask/' + $route.params.idGruppo)" class="mdi mdi-plus right"></i>
                            <i @click="opentask=!opentask" v-if="opentask" class="mdi mdi-chevron-up right"></i>
                            <i @click="opentask=!opentask" v-if="!opentask" class="mdi mdi-chevron-down right"></i>
                        </div>
                    </div>
        
                    <div id="task">
                        <ul class="collection">
                        <li v-for="task in info.tasks" :key="task.id" class="collection-item single-evento card">
                          <form>
                            <p style="display: inline;">
                              <input type="checkbox" class="filled-in" :id="'filled-in-box' + task.id" :checked="task.status == '0' ? false : true" @change="completaTask(task.id)" />
                              <label :for="'filled-in-box' + task.id">{{task.title}}</label>
                            </p>
                            <i class="mdi mdi-delete right bigright" @click="deleteTask(task.id)" v-if="isGroupAdmin"></i>
                            <i class="mdi mdi-pencil right bigright" @click="gotoTask(task.id)"></i>
                          </form>

                        </li>
                      </ul>
                    </div>
                </Collapsible>

                <div>
                  <div class="customTrigger">
                      <i class="mdi mdi-file-outline grey-text left"></i>
                      <p>Documenti</p>
                  </div>
                  <div class="tendinaI title">
                      <input type="file" id="fileToAdd" style="display: none" @change="uploadFile" />
                      <i class="mdi mdi-plus right" @click="addAllegato"></i>
                  </div>
                </div>
        
                <div id="files">
                  <ul class="collection documenti">
                    <li class="collection-item" v-for="doc in info.documenti" v-bind:key="doc.id">
                      <i class="mdi mdi-file-outline left"></i><a :href="doc.path" target="_BLANK">{{doc.name}}</a>
                      <div class="right">
                        {{parseDateTime(doc.date)}}
                        <i class="material-icons delete-attachment" @click="deleteAttachment(doc.id)">delete</i>
                      </div>
                    </li>
                  </ul>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script scoped>
import axios from 'axios'
import router from '../router'
import store from '../store'
import 'vue-collapsible-component/lib/vue-collapsible.css';
import Collapsible from 'vue-collapsible-component';
import jQuery from 'jquery';

export default {
  name: "Group",
  components:{Collapsible},
  data: function () {
        return {
            email:"",
            psw:"",
            info:{},
            root:"",
            opengruppi:true,
            openmembri:true,
            openeventi:false,
            opentask:false,
            openchat:true,
            opendocumenti:true,
            nascondimenu:false
        };
    },
    watch: {
      $route: function () {
        location.reload()
      }
    },
    beforeMount(){
        if(!store.state.loggedIn) {
          this.goTo("/")
        }

        document.body.style.backgroundColor = "#f5f5f5";
        document.body.style.color = "#455A64";
        localStorage.setItem("idGruppo",this.$route.params.idGruppo)
        
        var link="group/getgruppo" + "?id=" + this.$route.params.idGruppo
        axios.post(link, { token: store.getters.token })
          .then((response) => {
            this.info = response.data.gruppo
            this.info.subGroup = response.data.sottogruppi
            this.info.user_invited = response.data.invitati
            this.info.eventi = response.data.eventi
            this.info.tasks = response.data.tasks
            this.info.documenti = response.data.documenti
            if (this.info.eventi.length > 0) {
              this.openeventi = true
            }
            if (this.info.tasks.length > 0) {
              this.opentask = true
            }
          })
          .catch(function (error) {
            console.log(error);
          })
       
    },
    computed: {
      isGroupAdmin () {
        if (parseInt(this.info.user_admin) == this.info.curruser) {
          return true
        }

        return false
      }
    },
    methods:{
        goTo: function (url){
            router.push(url);
        },
        modificaGruppo: function(id){
            console.log("id gruppo: ",id)
        },
        addAllegato (evt) {
          evt.preventDefault();
          evt.stopPropagation();
          jQuery('#fileToAdd').click();
        },

        uploadFile () {
          let formData = new FormData();

          formData.append("attachment", jQuery('#fileToAdd').get(0).files[0]);
          formData.append("dati", JSON.stringify({
            token: store.getters.token,
            id_group: this.$route.params.idGruppo
          }))

          axios.post("allegati/add", formData)
          .then((resp) => {
            console.log(resp);
            location.reload();
          })
          .catch(function (error) {
            console.log(error);
          })
        },
        parseDate (enDate) {
          return enDate.substr(8, 2) + '/' + enDate.substr(5, 2) + '/' + enDate.substr(0, 4);
        },
        parseDateTime (enDate) {
          return enDate.substr(8, 2) + '/' + enDate.substr(5, 2) + '/' + enDate.substr(0, 4) + ' ' + enDate.substr(11, 5);
        },
        completaTask (id) {
          axios.post("task/completa?id=" + id, {
            token: store.getters.token,
          })
          .then((resp) => {
            console.log(resp)
          })
        },
        deleteAttachment (id) {
          axios.post("allegati/delete?id=" + id, { token: store.getters.token })
          .then(() => {
            location.reload();
          })
          .catch(function (error) {
            console.log(error);
          })
        },
        createCalendarLink (item) {
          console.log(item);
          return 'https://www.google.com/calendar/render?action=TEMPLATE&text=' +
            encodeURIComponent(item.title) +
            '&details=' +
            encodeURIComponent(item.descrizione) +
            '&location=' +
            encodeURIComponent(item.location) +
            '&dates=' + this.formatTimeForCal(item.event_start) + '%2F' + this.formatTimeForCal(item.event_end);
        },
        formatTimeForCal (dtime) {
          return dtime.replace(/-/g, '').replace(/:/g, '').replace(' ', 'T')
        },
        gotoTask (id) {
          this.$router.push('/showTask/' + id )
        },
        async deleteTask (id) {
          await axios.post("task/delete?id=" + id, {
            token: store.getters.token
          })

          location.reload()
        }
    }
};
</script>

<style scoped>
    
    p{
        font-size: 18px;
    }
    
    #sottogruppi a {
      color: #ff4c4e;
    }
    
    #sottogruppi a:hover {
      cursor: pointer;
    }

    .title p, .title i {
        color: #ff5555;
    }

    .title p{
        font-size: 18px;
    }

    .title i{
        font-size: 20px;
    }

    .customTrigger p{
        color: #ff5555;
        font-size: 18px;
    }

    .customTrigger i{
        font-size: 18px;
    }

    .grid-subgroup {
        display: grid;
        grid-template-columns: repeat(3,auto);
        grid-template-rows: repeat(2, auto);
        grid-column-gap: 12px;
        grid-row-gap: 12px;
        text-align: center;
    }
    
    .grid-subgroup  p {
        text-transform: capitalize;
        border: 1px solid gray;
        border-radius: 6px;
        min-height: 60px;
        vertical-align: center;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }

    a {
        color: #ff4c4e;
        text-decoration: none;
        margin-top: 15px;
        font-size: 16px;
        line-height: 26px;
    }

    .tendinaI{
        float: right;
        margin-top: -32px;
    }

    .tendinaI i{
        font-size: 22px;
    }

    .collection{
        border: 0px;
        padding: 0px;
    }

    .collection-item {
        border-bottom: 1px solid #eeeeee;
        background-color: transparent;
    }
    
    .avatar-img {
        height: 30px;
        width: 30px;
        border: 1px solid #ff4c4e;
        margin-right: 8px;
        float: left;
    }
    @media screen and (max-width: 1024px)
    {
        p{
            font-size: 23px;
        }
    
        .title p{
            font-size: 24px;
        }

        .title i{
            font-size: 27px;
        }

        .customTrigger p{

            font-size: 23px;
        }

        .customTrigger i{
            font-size: 26px;
        }

        .grid-subgroup  p {
        
            font-size: 22px;
        }

        .collection-item p{
            margin-top: 9px;
        }

        a {
            font-size: 22px;
        }

        .tendinaI i{
            font-size: 26px;
        }
        
        .avatar-img {
            height: 45px;
            width: 45px;
        }
        
        .documenti{
            font-size: 22px;
        }
    }

    @media screen and (max-width: 768px) {

        a {
            font-size: 16px;
        }
    }

#files {
  clear: both;
}

.card.single-evento {
  background-color: #fff;
  box-shadow: none;
  margin-bottom: 20px;
  position: relative;
}

.card.single-evento i {
  font-size: 11px;
}

.card.single-evento p {
  margin-top: 5px;
  font-size: 13px;
}

.card.single-evento a {
  width: 100%;
  height: 100%;
  display: block;
}

.linkto-map i, .linkto-cal i {
  font-size: 40px !important;
}

.linkto-map, .linkto-cal {
  margin-top: 0 !important;
  position: absolute;
  top: 50%;
  right: 30px;
  font-size: 40px;
  transform: translate(0, -50%);
  width: auto !important;
  height: auto !important;
}

.linkto-cal {
  right: 70px;
}

.delete-attachment {
  vertical-align: sub;
  cursor: pointer;
}

.bigright {
  float: right !important;
  font-size: 19px !important;
  cursor: pointer !important;
}
</style>


