<template>
  <div class="container-fluid">
    <div class="corpo">
        <div class="row">
            <div class="offset-md-2 col-md-8">
                <div v-if="task" id="showTask">
                    <h4>{{task.title}} <i :class="'mdi ' + (task.type == 0 ? 'mdi-heart-outline' : 'mdi-briefcase-outline') + ' right'"></i></h4>
                    <p>{{task.descrizione}}</p>
                    <p><i class="mdi mdi-calendar-clock"></i>{{task.creato_il}} - {{task.creato}}</p>
                    <div v-if="task.utenti_invitati.length > 0" class="input-field">
                        <i class="mdi mdi-account-multiple prefix"></i>
                        <div class="blocco-utenti">
                            <ul class="elenco-utenti">
                                <li v-for="utente in task.utenti_invitati" :key="utente.id" class="utente">
                                    <img :src="$store.getters.baseUrl + utente.picture" class="circle avatar-img">
                                    {{utente.last_name.charAt(0).toUpperCase() + utente.last_name.slice(1)}} {{utente.first_name.charAt(0).toUpperCase() + utente.first_name.slice(1)}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CreaNavBar :pagina="posAttuale" :paginaPrecedente="posPrecedente" :trashCallback="trashCallback($route.params.idTask)"/>
    </div>
  </div>
</template>

<script scoped>
import axios from 'axios'
import router from '../router'
import store from '../store'
import CreaNavBar from '../Components/CreaNavBar.vue'

export default {
  name: "showTask",
  components: {CreaNavBar },
  data: function () {
        return {
            task: false,
            root: "",
            posAttuale: "",
            posPrecedente: "",
        };
    },
    beforeMount(){
        document.body.style.backgroundColor = "#f5f5f5";
        document.body.style.color = "#455A64";
        this.posAttuale= localStorage.posAttuale
        this.posPrecedente= localStorage.posPrecedente
        if (this.$route.params.idTask) {
            var link = "task/get?id=" + this.$route.params.idTask
            axios.post(link,{
                token: store.getters.token
            })
            .then((response) => {
                console.log(response.data)
                this.task = response.data.task[0]
            })
            .catch(function (error) {
                console.log(error);
            })
        }
        
    },
    methods:{
        goTo: function (url){
            router.push(url);
        },
        trashCallback (id) {
          return async () => {
            await axios.post("task/delete?id=" + id, {
              token: store.getters.token
            })

            return this.$router.push('/task')
          }
        }
    }
};
</script>

<style scoped>

    .corpo{
        margin-top: 7%;
        color: #707070;
    }
    .corpo h4{
        font-size: 22px;
        line-height: 36px;
        margin: 1.5rem 0 0.5rem 0;
        color: #707070;
        text-transform:uppercase;
        font-weight: 700;
    }

    i.right {
        font-size: 19px;
    }

    .input-field .prefix {
        margin-top: 4px;
        font-size: 23px;
    }
    .input-field label {
        font-size: 14px;
    }

    .mdi{
        margin-right: 3px;
    }

    
    .avatar-img {
        height: 30px;
        width: 30px;
        border: 1px solid #ff4c4e;
        margin-right: 8px;
        float: left;
    }

    .elenco-utenti {
        padding-left: 0px;
    }

    .utente {
        height: 30px;
        line-height: 30px;
    }

    .blocco-utenti {
        margin-left: 30px;
    }

    @media screen and (max-width: 1024px){
        
        .corpo{
            margin-top: 10%;
        }

        .corpo h4{
            font-size: 28px;
        }

        .input-field label{
            font-size: 22px;
        }

        .input-field .prefix{
            margin-top: 4px;
            font-size: 30px;
        }

        i.right {
            font-size: 24px;
        }

        #showTask p{
            font-size: 23px;
        }

        .avatar-img {
            height: 45px;
            width: 45px;
        }

        .utente {
            height: 45px;
            line-height: 45px;
        }

        .blocco-utenti {
            margin-left: 45px;
        }
    }

    @media screen and (max-width: 768px) {

        .corpo{
            margin-top: 12%;
        }
    }
</style>


