<template>
    <div class="container-fluid" v-show="$store.state.loggedIn && !visualizzaCrea.includes(pagina) && !modifica.includes(pagina)">
      <div class="col-md-12">
        <div v-show="!open"  class="overlayShadow"></div>
        <div v-show="!open && posAttuale!='sondaggi'" 
        :class="'overlay-body z-depth-1 '+(bodyMarginEvent.includes(posAttuale) ? 'overlay-bodyMarginEvent':'overlay-bodyMargin')">
            <ul id="action-list">
                <li>
                    <a @click="goTo('/creaEvent')" v-show="createEvent.includes(posAttuale)">
                        <i class="mdi mdi-calendar"></i>Crea Evento
                    </a>
                </li>
                <!--<li v-show="createPoll.includes(posAttuale)">
                    <a @click="goTo('/CreaSondaggio')">
                        <i class="mdi mdi-checkbox-multiple-marked-outline"></i>Crea Sondaggio
                        </a>
                </li>-->
                <li>
                    <a @click="goTo('/creaGruppo')" v-show="createGroup.includes(posAttuale)">
                        <i class="mdi mdi-google-circles-extended"></i>Crea Gruppo
                    </a>
                </li>
                <li>
                    <a @click="goTo('/creaTask')" v-if="pagina != 'chat'">
                        <i class="mdi mdi-format-list-checks"></i>Assegna Task
                    </a>
                </li>
                <!--<li id="calendario-view-btn" class="" v-show="posAttuale=='event'">
                    <a href="#">
                        <i class="mdi mdi-calendar-text"></i>Vista Calendario
                    </a>
                </li>-->
                <!--<li>
                    <a v-show="nascondimenu" v-on:click="nascondimenu= !nascondimenu">
                        <i class="mdi mdi-eye-off"></i>Nascondi Menù
                    </a>
                    <a v-show="!nascondimenu" v-on:click="nascondimenu= !nascondimenu">
                        <i class="mdi mdi-eye"></i>Mostra Menù
                    </a>
                </li>-->
              </ul>
          </div>
          <div id="main-footer-menu" :class="'footer-menu' + ((nascondimenu || posAttuale=='sondaggi' )? ' nascosto' : ' activeFooter')">
              <ul>
                  <li>
                      <a v-show="!nascondimenu && posAttuale!='sondaggi'" @click="goTo('/group')" :class="(posAttuale=='group' ? ' rosso' : ' nonroot')">
                      <i class="mdi mdi-google-circles-extended"></i>
                          <span>Gruppi</span>
                      </a>
                  </li>

                  <li>
                      <a  v-show="!nascondimenu && posAttuale!='sondaggi'" @click="goTo('/event')" :class="(posAttuale=='event' ? ' verde' : ' nonroot')"> 
                      <i class="mdi mdi-calendar-clock"></i>
                          <span>Calendario</span>
                      </a>
                  </li>

                  <li class="action-btn-container" :style="(pagina != 'chat') ? 'opacity: 1' : 'opacity: 0'">
                      <center>
                        <div v-show="posAttuale!='sondaggi'" v-on:click="open = !open" id="action-btn" :class="'z-depth-1 action-btn '+ classeSfondo">
                            <i v-show="open && posAttuale!='sondaggi'" class="mdi mdi-menu"></i>
                            <i v-show="!open && posAttuale!='sondaggi'" class="mdi mdi-close white-text" ></i>
                        </div>
                        <div v-show="posAttuale=='sondaggi'" @click="goTo('/event')" id="action-btn" :class="'z-depth-1 action-btn '+ classeSfondo">
                            <i class="mdi mdi-close white-text" ></i>
                        </div>
                      </center>
                  </li>

                  <li>
                      <a v-show="!nascondimenu && posAttuale!='sondaggi'" @click="goTo('/task')" :class="(posAttuale=='task' ? ' giallo' : ' nonroot')">
                          <i class="mdi mdi-format-list-checks"></i>
                          <span>Task</span>
                      </a>
                  </li>

                  <li>
                      <a v-show="!nascondimenu && posAttuale!='sondaggi'" @click="goTo('/chat')" :class="(posAttuale=='chat' ? ' blu' : ' nonroot')"> 
                          <i class="mdi mdi-forum-outline"></i>
                          <span>Chat</span>
                      </a>
                  </li>
              </ul>
          </div>
      </div>
    </div>
</template>
<script>
import router from '../router'

export default {
  name: "NavBarBassa",
  data: function () {
      return {
          open:true,
          nascondimenu:false,
          posAttuale:"",
          classeSfondo:false,
          classeColore:false,
          tendina:false,
          modifica:['modifica','profilo'],
          visualizzaCrea:['creaEvent','creaSondaggio','creaGruppo','creaTask','viewChat','showTask','showEvent'],
          bodyMarginEvent:['event','modifica','profilo'],
          createEvent:['event','modifica','profilo'],
          createPoll:['event','task','modifica','profilo'],
          createGroup:['group','modifica','profilo'],
          blu:['chat'],
          giallo:['task','showTask','creaTask'],
          rosso:['group','profilo','modifica','creaGruppo','viewGruppo'],
          verde:['event','sondaggi','creaEvent','creaSondaggio','showEvent']
      };
  },
  props:{
      pagina:String,
  },
  watch: {
      pagina (val) {
        this.posAttuale = val
        this.pagina = this.posAttuale
        this.posAttuale=this.pagina

        switch(true){
            case this.blu.includes(this.pagina):
                this.classeSfondo="bluSfondo"
                this.classeColore="blu"
                break
            case this.giallo.includes(this.pagina):
                this.classeSfondo="gialloSfondo"
                this.classeColore="giallo"
                break
            case this.verde.includes(this.pagina):
                this.classeSfondo="verdeSfondo"
                this.classeColore="verde"
                break
            case this.rosso.includes(this.pagina):
                this.classeSfondo="rossoSfondo"
                this.classeColore="rosso"
                break
            default:
                this.visibile=false
                break
        }

        localStorage.setItem("classeSfondo", this.classeSfondo)
        localStorage.setItem("classeColore", this.classeColore)
        localStorage.setItem("posAttuale", this.posAttuale)

      }
  },
  beforeMount(){
        if (localStorage.classeSfondo) {
            this.classeSfondo = localStorage.classeSfondo
        }
        if (localStorage.classeColore) {
            this.classeColore = localStorage.classeColore
        }
        if (localStorage.posAttuale) {
            this.posAttuale = localStorage.posAttuale
        }
        //localStorage.removeItem("classeSfondo")
    },
  methods:{
    goTo: function (url){
        this.open=true
        router.push(url);
    }

  }
}
</script>
<style scoped>
.menu-filter {
    display: grid;
    position: fixed;
    top: 0;
    left: -3px;
    width: 100%;
    color: gray;
    background-color: white;
    border-radius: 24px;
    padding: 100px 40px 40px 40px;
    grid-template-columns: auto auto 2rem;
    grid-template-rows: repeat(2, auto);
    grid-row-gap: 24px;
    z-index: 2;
    margin: 4px;
    text-transform: uppercase;
}

.menu-filter i{
    font-size: 22px;
}

.nascondi{
    background-color: transparent !important;
}

.activeFooter{
    background-color: #f5f5f5 !important;
}

.nonroot{
    color:#455A64 !important
}

.footer-menu{
    display: block;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 65px;
    z-index: 2;
}

.action-btn{
    position: absolute;
    height: 56px;
    width: 56px;
    border: 8px solid aliceblue;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    top: -14px;
    left: 48%;
    z-index: 2;
    cursor:pointer;
}

.action-btn i{
    font-size: 19px;
}

.action-more-menu {
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: row;
    bottom: 0;
    z-index: 2;
    height: 50px;
}

.footer-menu ul {
    display: inline-block;
    width: 100%;
    padding: 7px 0px 0 0px;
    margin: 0px;
}
.footer-menu li {
    width: 20%;
    display: inline-block;
    height: 100%;
    text-align: center;
    cursor:pointer;
    
}
.footer-menu li i, .footer-menu li span{
    display: block;
    font-size: 20px;
}

.footer-menu li span{
    display: block;
    font-size: 13px;
}

.footer-menu a{

    text-decoration: none;
}

.overlay-bodyMargin{
    top: 69%;
}

.overlay-bodyMarginEvent{
    top: 65%;
}

.overlay-body li{
    cursor:pointer;
}

.overlay-body{
    background-color: white;
    text-align: left;
    position: fixed;
    z-index: 2;
    padding: 18px;
    width: 245px;
    border-radius: 16px;
    margin-bottom: 12px;
    left: 41%;
    padding: 24px 23px;
}
.z-depth-1{
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%)
}

#action-list li, #action-list li a {
    color: #546E7A !important;
    font-size: 18px;
    text-decoration: none;
}

.overlayShadow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0) 100%);
}

@media screen and (max-width: 1024px)
{
    .overlay-bodyMargin{
        top: 79%;
    }

    .overlay-bodyMarginEvent{
        top: 77%;
    }

    .overlay-body{
        left: 39%;
        padding: 24px 15px;
    }

    .action-btn{
        left: 47%;
    }

    #action-list li a{
        font-size: 23px;
    }

    .footer-menu{
        height: 82px;
    }

    .footer-menu li i, .footer-menu li span{
        font-size: 28px;
    }

    .footer-menu li span{
        font-size: 20px;
    }

}

@media screen and (max-width: 768px) {
    
    .overlay-body{
        left: 33%;
        padding: 24px 12px;
    }

    .overlay-bodyMarginEvent{
        top: 69%;
    }

    .overlay-bodyMargin{
        top: 73%;
    }

    .action-btn{
        left: 45%;
    }
}
</style>
