<template>
  <div class="container-fluid" >
    <div class="corpo">
        <div class="row">
            <div class="offset-md-2 col-md-8 offset-md-2">
                <br>
                <b>DA COMPLETARE ({{tasks.da_completare.length}})</b>
                <div v-for="task in tasks.da_completare" :key="task.id" @click="apriTask(task.id)">
                    <div :class="'card ' + (task.type == 0 ? 'personal' : 'business') + (task.scaduto == 1 ? ' scaduto': '')">
                        <div class="card-content">
                            <span class="card-title">{{task.title}}</span>
                            <i :class="'mdi ' + (task.type == 0 ? 'mdi-heart-outline' : 'mdi-briefcase-outline') + ' right'"></i>
                            <p>{{task.descrizione}}</p>
                            <div>
                                <span>
                                    <i class="mdi mdi-calendar-clock"></i>
                                    {{task.scaduto == 1 ? 'Scaduto' : task.creato_il}}
                                </span>
                                <i class="mdi mdi-check right"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div v-if="tasks.completati.length > 0">
                    <b>COMPLETATI ({{tasks.completati.length}})</b>
                    <div v-for="task in tasks.completati" :key="task.id" @click="apriTask(task.id)">
                        <div :class="'card ' + (task.type == 0 ? 'personal' : 'business') + ' completato'">
                            <div class="card-content">
                                <span class="card-title">{{task.title}}</span>
                                <i :class="'mdi ' + (task.type == 0 ? 'mdi-heart-outline' : 'mdi-briefcase-outline') + ' right'"></i>
                                <p>{{task.descrizione}}</p>
                                <div>
                                    <span>
                                        <i class="mdi mdi-calendar-clock"></i>
                                        {{task.creato_il}}
                                    </span>
                                    <i class="mdi mdi-check right"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script scoped>
import axios from 'axios'
import router from '../router'
import store from '../store'
import utils from '../utils'

export default {
  name: "Task",
  data: function () {
      return {
        email:"",
        psw:"",
        info:null,
        root:"",
        tasks: [],
        tasksBase: []
      };
    },
    beforeMount () {
        utils.setBgColor("#f5f5f5", "#455A64");

        axios.post("task/get", { token:store.getters.token })
        .then((response) => {
            this.tasksBase = response.data.task
            this.tasks = response.data.task
            console.log(this.tasksBase)
        })
        .catch(function (error) {
            console.log(error);
        });


        store.subscribe((mutation, state) => {
          console.log(state.searchGroup)
          switch (mutation.type) {
            case 'searchGroup':
              this.tasks = {
                completati: this.tasksBase.completati.filter((item) => {
                  console.log(item.title)
                  return item.title.toLowerCase().indexOf(state.searchGroup.q.toLowerCase()) !== -1
                }),
                da_completare: this.tasksBase.da_completare.filter((item) => {
                  console.log(item.title)
                  return item.title.toLowerCase().indexOf(state.searchGroup.q.toLowerCase()) !== -1
                })
              }
              break;
          }
        });
    },
    methods:{
        goTo: function (url){
            router.push(url);
        },
        apriTask: function (idTask) {
            router.push('/showTask/' + idTask)
        }
    }
};
</script>

<style scoped>

    .card {
        margin-top: 2%;
        border-radius: 3px;
        border: 1px solid rgb(252, 166, 73);
        cursor:pointer;
        box-shadow: none;
    }

    .card-content i{
        color: rgb(252, 166, 73);
    }
    .card-content span i{
        color: #455A64;
    }

    .card .card-content p {
        font-size: 18px;
    }

    .card-content i{
        margin-right: 4px;
    } 

    .card-content span{
        font-size: 15px;
    }

    .mdi{
        font-size: 16px;
    }

    .scaduto {
        border: 1px solid #ff5555;
        color: lightgray;
    }

    .scaduto .card-title {
        line-height: 48px;
        color: lightgray;
        font-weight: 500;
    }

    .scaduto i, .scaduto span, .scaduto span i {
        color: lightgray;

    }

    .card-title {
        line-height: 48px;
        color: rgb(252, 166, 73);
        font-weight: 500;
    }

    .ico-container i, .group-nbr{
        font-size: 20px;
    }

    .group-nbr{
    color: #455A64; 
    font-size: 19px; 
    margin-left: 1px;
    }
    .ico-container i, .container-freccia i {
        color:rgb(252, 166, 73);

    }
    .completato {
        border: 1px solid lightgrey;
        color: lightgray;
    }

    .completato .card-content , .completato .card-title {
        color: lightgray;
    }
    .completato .card-content p ,.completato .card-content i {
        color: lightgray;
    }

    @media screen and (max-width: 1024px)
    {
        .container-freccia i{
            font-size: 27px;
        }

        .card .card-content p {
            font-size: 21px;
        } 

        .card-content span{
            font-size: 20px;
        }
        
        .ico-container i{
            font-size: 23px;
        }

        .group-nbr{
            font-size: 22px;
        }

        .mdi{
            font-size: 28px;
        }
    }

    @media screen and (max-width: 768px) {

        .ico-container i{
            font-size: 22px;
        }

        .group-nbr{
            font-size: 21px;
        }

        .card .card-content p {
            font-size: 20px;
        }

        .card-content span{
            font-size: 17px;
        }

        .container-freccia i{
            font-size: 25px;
        }

        .mdi{
            font-size: 24px;
        }
    }
    
</style>


