import store from './store'

export default {
  setBgColor (bgColor, color) {
    document.body.style.backgroundColor = bgColor;
    document.body.style.color = color;
  },

  dateEngToIt (dateEng) {
    return dateEng ?
      (dateEng.substr(8, 2) + '/' + dateEng.substr(5, 2) + '/' + dateEng.substr(0, 4)) :
      '';
  },

  createRoute (path, name, component) {
    return {
      path,
      name,
      component,
      beforeEnter (to, from, next) {
        if( store.state.loggedIn == false ) {
          next({ name: 'login' })
        } else {
          next()
        }
      }
    }
  }
}
