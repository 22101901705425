<template>
  <div class="container-fluid">
    <div class="corpo">
        <div class="row" >
            <div class="offset-md-1 col-md-10 offset-md-1">
                <!--<div class="card sondaggi" @click="pollsAperti > 0 ? goTo('/sondaggi') : ''">
                    <div class="card-content">
                        <h1 v-if="pollsAperti > 0" class="card-title">Ancora {{pollsAperti}} sondaggi a cui rispondere</h1>
                        <h1 v-if="pollsAperti == 0" class="card-title">Non ci sono sondaggi a cui rispondere</h1>
                        <div v-if="pollsAperti > 0" class="right container-freccia"><i class="mdi mdi-chevron-right"></i></div>
                    </div>
                </div>-->

                <v-calendar
                  class="custom-calendar max-w-full"
                  v-if="attributes.length"
                  :masks="masks"
                  :attributes="attributes"
                  disable-page-swipe
                  is-expanded
                >
                  <template v-slot:day-content="{ day, attributes }">
                    <div class="flex flex-col h-full z-10 overflow-hidden">
                      <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
                      <div class="flex-grow overflow-y-auto overflow-x-auto">
                        <router-link :to="(attr.customData.type == 'event' ? '/showEvent/' : '/showTask/') + attr.customData.linkId"
                          v-for="attr in attributes"
                          :key="attr.key"
                          class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1 block-dat"
                          :class="attr.customData.class"
                          
                        >
                          {{ attr.customData.title }}
                        </router-link>
                      </div>
                    </div>
                  </template>
                </v-calendar>


                <div v-for="evento in this.info" :key="evento.id"  class="card">
                  <div class="card-content">
                    <h4 class="card-title firstupper">{{evento.title}}
                      <i v-if="evento.type == '1'" class="mdi mdi-briefcase-outline right"></i>
                      <i v-if="evento.type == '0'" class="mdi mdi-heart-outline right"></i>
                    </h4>
                    <p>{{evento.descrizione.charAt(0).toUpperCase() + evento.descrizione.slice(1)}}</p>
                    <br>
                    <p><i class="mdi mdi-calendar-clock"></i> {{evento.format_date}}</p>
                    <p><i class="mdi mdi-map-marker"></i> {{evento.location}}</p>
                    <div class="right container-freccia" v-if="evento.creatore"><i class="mdi mdi-pencil" @click="goTo('/showEvent/' + evento.id)"></i></div>
                  </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script scoped>
import axios from 'axios'
import router from '../router'
import store from '../store'

export default {
  name: "Event",
  data: function () {
        return {
          email: "",
          psw: "",
          info: null,
          root: "",
          polls: false,
          pollsAperti: 0,
          masks: {
            weekdays: 'WWW',
          },
          attributes: []
        };
    },
    beforeMount(){
        document.body.style.backgroundColor = "#f5f5f5";
        document.body.style.color = "#455A64";

        axios.post("event/getfilter",{
          token: store.getters.token
        })
        .then((response) => {
          this.attributes = response.data.eventi.map((item) => {
            return {
              ...item,
              customData: {
                title: item.title,
                class: item.type == 'event' ? 'bg-evento text-white' : 'bg-task text-white',
                type: item.type,
                linkId: (item.key + "").replace('task', '')
              },
              dates: Date.parse(item.date)
            }
          })

          console.log(this.attributes)
        })
        .catch(function (error) {
          console.log(error);
        })
        axios.post("poll/get",{
          token: store.getters.token
        })
        .then((response) => {
          this.polls = response.data.polls
          this.pollsAperti = 0
          this.polls.forEach((poll) => {
            if (poll.aperto == 1) {
              this.pollsAperti++
            }
          })
        })
        .catch(function (error) {
          console.log(error);
        })
    },
    methods:{
        goTo: function (url){
            router.push(url);
        }
    }
};
</script>

<style lang="postcss" scoped>

    .card {
        margin-top: 2%;
        border-radius: 30px;
        cursor:pointer;
    }

    .card-title {
        line-height: 48px;
        color: rgb(44, 178, 169);
        font-weight: 500;
    }

    .ico-container i, .group-nbr{
        font-size: 20px;
    }

    .mdi{
        font-size: 18px;
    }

    .group-nbr{
    color: #455A64; 
    font-size: 19px; 
    margin-left: 1px;
    }

    .card-content i{
        color:rgb(44, 178, 169);
    }
    .ico-container i, .container-freccia i {
        font-size: 24px;
        color:rgb(44, 178, 169);
    }

    .sondaggi {
      box-shadow: none;
      border-radius: 2px;
    }

    #sondaggio-open-container {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        padding-top: 68px;
    }

    #sondaggio-open-container h4{
        font-weight: 500;
        color: #2CB2A9;
        text-transform: capitalize;
    }

    #sondaggio-open-container .info{
        float:left;
        width: 97%;
    }
    #sondaggio-open-container .icon-chat-container {
        font-size: 24px;
        color:rgb(44, 178, 169);
        float:left;
        margin-top: 2%;
    }

    @media screen and (max-width: 1024px) {
        .container-freccia i{
            font-size: 27px;
        }

        .card .card-content p {
            font-size: 21px;
        }

        .ico-container i{
            font-size: 23px;
        }

        .group-nbr{
            font-size: 22px;
        }

        .mdi{
            font-size: 28px;
        }
    }

    @media screen and (max-width: 768px) {

        .ico-container i{
            font-size: 22px;
        }

        .group-nbr{
            font-size: 21px;
        }

        .card .card-content p {
            font-size: 20px;
        }

        .container-freccia i{
            font-size: 25px;
        }

        .mdi{
            font-size: 24px;
        }
    }

::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}

div >>> .custom-calendar.vc-container {
  border-radius: 0;
  width: 100%;
}

div >>> .custom-calendar.vc-container .vc-header {
  background-color: #f1f5f8;
  padding: 10px 0;
}
div >>> .custom-calendar.vc-container .vc-weeks {
  padding: 0;
}
div >>> .custom-calendar.vc-container .vc-weekday {
  background-color: #f8fafc;
  border-bottom: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
  padding: 5px 0;
}
div >>> .custom-calendar.vc-container .vc-day {
  padding: 0 5px 3px 5px;
  text-align: left;
  height: 90px;
  min-width: 90px;
  background-color: white;
}

div >>> .custom-calendar.vc-container .vc-day .weekday-1,
div >>> .custom-calendar.vc-container .vc-day .weekday-7 {
  background-color: #eff8ff;
}

div >>> .custom-calendar.vc-container .vc-day:not(.on-bottom) {
    border-bottom: 1px solid #b8c2cc;
}

div >>> .custom-calendar.vc-container .vc-day:not(.on-bottom).weekday-1 {
  border-bottom: 1px solid #b8c2cc;
}

div >>> .custom-calendar.vc-container .vc-day:not(.on-bottom):not(.on-right) {
    border-right: 1px solid #b8c2cc;
}

div >>> .custom-calendar.vc-container .vc-day-dots {
  margin-bottom: 5px;
}

@media (max-width: 480px) {
  div >>> .custom-calendar.vc-container .vc-day {
    text-align: left;
    height: inherit !important;
    min-width: 1px !important;
    overflow: auto !important;
  }
}

.bg-evento {
  background-color: rgb(44, 178, 169)!important;
  font-size: 13px;
}

.bg-task {
  background-color: rgb(252, 166, 73) !important;
  font-size: 13px;
}

div >>> .block-dat {
  display: block;
  text-decoration: none;
}
</style>


