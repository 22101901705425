<template>
  <div class="container-fluid">
    <div class="corpo">
        <div class="row">
            <div class="offset-md-2 col-md-8 offset-md-2">
                <div class="card user " @click="goTo('/viewChat/' + item.id)" v-for="item in info" v-bind:key="item.code_group">
                    <div class="card-stacked">
                        <div class="card-content">
                            <p class="card-title">{{item.titolo}}</p>
                            <p v-if="item.last_message">
                              {{item.last_message.message_date.substr(11, 5)}}<br>
                              <b>{{item.last_message.first_name}}:</b> {{item.last_message.message.substr(0, 50)}}</p>
                            <p v-if="!item.last_message">Ancora nessun messaggio in questo gruppo</p>
                        </div>
                    </div>
                    <div class="icon-chat-container">
                        <i class="mdi mdi-chevron-right"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script scoped>
import axios from 'axios'
import router from '../router'
import store from '../store'
import utils from '../utils'

export default {
  name: "Chat",
  data: function () {
    return {
      email: "",
      psw: "",
      info: null,
      infoBase: null,
      root: ""
    };
  },
  async beforeMount () {
    utils.setBgColor("#f5f5f5", "#455A64");

    try {
      let resp = await axios.post('chat/get', {
        token: store.getters.token
      })

      this.info = resp.data
      this.infoBase= resp.data
    } catch (e) {
      console.log(e)
    }

    store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case 'searchGroup':
          this.info = this.infoBase.filter((item) => {
            console.log(item.titolo.toLowerCase().indexOf(state.searchGroup.q.toLowerCase()))
            return item.titolo.toLowerCase().indexOf(state.searchGroup.q.toLowerCase()) !== -1
          })
          break;
      }
    });
  },
  methods: {
    goTo: function (url){
      router.push(url);
    }
  }
};
</script>

<style scoped>

    .corpo{
        margin-top: 5%;
    }


    .card {
        margin-top: 2%;
        border-radius: 30px;
        cursor:pointer;
    }

    .card .card-content {
        padding: 10px 23px;
        border-radius: 0 0 2px 2px;
    }

    .card-title {
        line-height: 48px;
        color: rgb(44, 121, 178) !important;
        font-weight: 500;   
    }

    .ico-container i, .group-nbr{
        font-size: 20px;
    }

    .group-nbr{
        color: #455A64; 
        font-size: 19px; 
        margin-left: 1px;
    }
    .card .user {
        display: flex;
        flex-direction: row;
    }

    .ico-container i, .container-freccia i {
        color:rgb(44, 121, 178);

    }

    .icon-chat-container {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto 1rem;
    }

    .chatImg {
        position: absolute;
        margin-left: 7%;
    }
    .icon-chat-container .mdi.mdi-chevron-right {
        font-size: 28px;
        color: #2C79B2;
    }

    .img-wrapper {
        padding: 18px 18px 12px 18px;
    }

    .img-wrapper img {
        height: 56px;
        background: #2C79B2;
        border-radius: 50%;

    }


     @media screen and (max-width: 1024px)
    {
        .corpo{
            margin-top: 10%;
        }

        .img-wrapper img{
            height: 86px;
        }
        .card{
            padding: 20px;   
        }

        .card .card-content{
            margin-left: 60px;
        }

        .container-freccia i{
            font-size: 27px;
        }

        .card .card-content p {
            font-size: 21px;
        } 

        .card-content span{
            font-size: 20px;
        }
        
        .ico-container i{
            font-size: 23px;
        }

        .group-nbr{
            font-size: 22px;
        }

        .mdi{
            font-size: 28px;
        }
    }

    @media screen and (max-width: 768px) {

        .ico-container i{
            font-size: 22px;
        }

        .group-nbr{
            font-size: 21px;
        }

        .card .card-content p {
            font-size: 20px;
        }

        .card-content span{
            font-size: 17px;
        }

        .container-freccia i{
            font-size: 25px;
        }

        .mdi{
            font-size: 24px;
        }
    }

    .card-title {
        margin-bottom: 8px !important;
    }

    .card-title + p {
       margin-bottom: 14px;
    }
</style>


