<template>
  <div class="container-fluid">
    <div class="col-md-12">
      <div id="chat-footer-menu" class="footer-menu">
        <textarea v-model="messaggio" placeholder="Testo del messaggio" @keydown="tryToSend"></textarea>
        <ul>
          <li>
            <a @click="goTo(posPrecedente)">
              <i class="mdi mdi-close"></i>
            </a>
          </li>
          <li>
            <a href="https://meet.google.com/new" target="_BLANK">
              <i class="mdi mdi-message-video"></i>
            </a>
          </li>
          <!--<li>
            <a @click="chooseFiles()" >
              <i class="mdi mdi-attachment"></i>
              <input id="fileUpload" type="file" @change="onFileChange" hidden />
            </a>
          </li>-->
          <li>
            <a @click="sendMessage">
              <i class="mdi mdi-send"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script scoped>
import axios from 'axios'
//import router from '../router'
import store from '../store'
import utils from '../utils'

export default {
  name: 'NavChat',
  data: function () {
    return {
      messaggio: '',
      percorsofile: '',
      file: '',
      open: false
    }
  },
  beforeMount () {
    utils.setBgColor('#f5f5f5', '#455A64')
  },
  methods: {
    inviaMessaggio () {
      this.messaggio = ''
    },
    chooseFiles () {
      document.getElementById('fileUpload').click()
    },
    onFileChange (e) {
      this.percorsofile = e.target.files[0];
      this.file = URL.createObjectURL(this.percorsofile)
    },
    async tryToSend (e) {
      if (e.code == 'Enter') {
        e.preventDefault()
        this.sendMessage()
      }
    },
    async sendMessage () {
      try {
        await axios.post('chat/new', {
          token: store.getters.token,
          idGroup: this.$route.params.id,
          message: this.messaggio
        })

        this.messaggio = ''
      } catch (e) {
        console.log(e)
      }
    },
    async createMeet (e) {
      e.preventDefault()
      try {
        await axios.post('chat/createmeet', {
          token: store.getters.token
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
<style scoped>
#chat-footer-menu {
  border-top: 1px solid #2C79B2;
  background-color: #f5f5f5;
  height: 120px;
}

.footer-menu {
  display: block;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.action-more-menu {
  position: fixed;
  width: 100%;
  flex-direction: row;
  bottom: 0;
  z-index: 2;
  height: 50px;
}

#chat-footer-menu ul {
  bottom: 0;
}

.footer-menu ul {
  display: inline-block;
  width: 100%;
  padding: 7px 0px 0 0px;
  margin: 0px;
}

.footer-menu li {
  width: 33.3333333%;
  display: inline-block;
  height: 100%;
  text-align: center;
  cursor: pointer;
}

.footer-menu li a {
  display: inline-block;
  color: #546E7A;
}

#chat-footer-menu ul li a i {
  font-size: 32px;
}

.mdi {
  color: #2C79B2;
}

#action-btn {
  background-color: #2C79B2;
  position: absolute;
  height: 56px;
  width: 56px;
  border: 8px solid aliceblue;
  border-radius: 50%;
  display: flex;
  left: 48%;
  flex-direction: column;
  justify-content: center;
  color: white;
  z-index: 98;
  top: 47px;
  cursor: pointer;
}

#action-btn i {
  color: #fff;
  font-size: 20px;
}

textarea {
  border: 0px;
  resize: vertical;
  outline: none;
  color: #2C79B2;
  padding: 10px;
  font-size: 16px;
}

.overlay-bodyMargin {
  top: 68%;
}

.overlay-bodyMargin i {
  margin-right: 7px;
  margin-top: 5px;
  color: #546E7A;
}

.overlay-body {
  background-color: white;
  text-align: left;
  position: fixed;
  z-index: 2;
  padding: 18px;
  width: 245px;
  border-radius: 16px;
  margin-bottom: 12px;
  left: 41%;
  padding: 24px 23px;
}

.overlay-body li {
  cursor: pointer;
}

.z-depth-1 {
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%)
}

#action-list li, #action-list li a {
  color: #546E7A !important;
  font-size: 18px;
  text-decoration: none;
  display: none !important;
}

.overlayShadow, .overlay-body {
  display: none !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0) 100%);
}

@media screen and (max-width: 1024px) {
  .overlay-body {
    left: 39%;
  }

  .overlay-bodyMargin {
    top: 82%;
  }

  textarea {
    font-size: 22px;
  }

  #chat-footer-menu ul li a i {
    font-size: 40px;
  }
  
  #action-btn i {
    color: #fff;
    font-size: 32px;
  }

  #action-btn {
    height: 65px;
    width: 65px;
  }
}

@media screen and (max-width: 768px) {
  .overlay-bodyMargin {
    top: 76%;
  }

  .overlay-body {
    left: 36%;
  }

  textarea {
    font-size: 22px;
  }
}
</style>
