<template slot="tag" slot-scope="props">
    <div class="container-fluid">
        <div v-if="evento !== false" id="evento" class="corpo">
            <div class="row">
                <div class="offset-md-1 col-md-10 offset-md-1">
                    <h5>Modifica Evento</h5>
                </div>

                <div class="offset-md-1 col-md-10 offset-md-1">
                    <div class="input-field col s12 ">
                        <i class="mdi mdi-calendar prefix primary-text"></i>
                        <input  type="text" v-model="nomeEvento">
                        <label  class="active">Titolo Evento</label>
                    </div>
               
                    <div class="input-field col s12">
                        <i class="mdi mdi-calendar-text prefix primary-text"></i>
                        <textarea  class="materialize-textarea" v-model="descEvento">
                        </textarea>
                        <label class="active">Descrizione Evento</label>
                    </div>
                    <div class="input-field col s12 ">
                        <i class="mdi mdi-calendar prefix primary-text active"></i>
                        <input  type="text" v-model="luogoEvento">
                        <label class="active">Luogo Evento</label>
                    </div>
                    <div class="input-field col s12">
                        <i class="mdi mdi-clock-start prefix"></i>
                        <input type="date" id="birth" name="birth" data-date-format="DD-MMMM-YYYY" v-model="dataInizio">
                        <label class="active">Data inizio evento</label>
                    </div>
                    <div class="input-field col s12">
                        <i class="mdi mdi-clock-in prefix"></i>
                        <input type="time" id="birth"  name="birth" data-date-format="hh:mm" v-model="oraInizio">
                        <label class="active">Ora inizio evento</label>
                    </div>
                    <div class="input-field col s12">
                        <i class="mdi mdi-clock-end prefix"></i>
                        <input type="date" id="birth" name="birth" data-date-format="DD-MMMM-YYYY" v-model="dataFine">
                        <label class="active">Data fine evento</label>
                    </div>
                    <div class="input-field col s12">
                        <i class="mdi mdi-clock-out prefix"></i>
                        <input type="time" id="birth"  name="birth" data-date-format="hh:mm" v-model="oraFine">
                        <label class="active">Ora fine evento</label>
                    </div>
                    <div v-if="!daGruppo" class="col s12 select">
                        <p>Invita:</p>
                        <multiselect 
                            v-model="valueUtenti" 
                            :options="optionsUtenti" 
                            :multiple="true" 
                            :close-on-select="false"
                            :max-height="150"
                            :height="0"
                            open-direction="bottom"
                            label="name" 
                            track-by="name"
                            placeholder="Utenti">
                        </multiselect>
                    </div>
                    <div class="col s12 sottogruppi">
                        <p>Tipo di evento:</p>
                        <div :class="'waves-effect waves-light btn ' + (sottogruppo=='personal' ? 'selezionato': 'noselezionato') "  @click="sottogruppo='personal'">
                            <i :class="'mdi mdi-heart-outline left ' + (sottogruppo=='personal' ? 'selezionatoi': 'noselezionatoi')"></i>personale
                        </div>
                        <div :class="'waves-effect waves-light btn '+(sottogruppo=='business' ? 'selezionato': 'noselezionato') " @click="sottogruppo='business'">
                            <i :class="'mdi mdi-briefcase-outline left ' + (sottogruppo=='business' ? 'selezionatoi': 'noselezionatoi')"></i>business
                        </div>
                    </div>
                </div>

                <div class="offset-md-1 col-md-10 offset-md-1">
                  <div class="row">
                    <div class="col-md-3 event-data">Invitati<br><span>4</span></div>
                    <div class="col-md-3 event-data">Non hanno risposto<br><span>1</span></div>
                    <div class="col-md-3 event-data">Parteciperanno<br><span>2</span></div>
                    <div class="col-md-3 event-data">Non parteciperanno<br><span>1</span></div>
                  </div>
                </div>
            </div>
        </div>
        <CreaNavBar :pagina="posAttuale" :paginaPrecedente="posPrecedente" @metodo="modificaEvento" :trashCallback="eliminaEvento"/>
    </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios'
import router from '../router'
import store from '../store'
import CreaNavBar from '../Components/CreaNavBar.vue'
import Multiselect from 'vue-multiselect'
import swal from 'sweetalert'
  
export default {
  name: "ShowEvent",
  components: { Multiselect,CreaNavBar },
  data: function () {
        return {
            evento: false,
            posAttuale:"",
            posPrecedente:"",
            nomeEvento:"",
            descEvento:"",
            luogoEvento:"",
            dataInizio: moment().format('YYYY-MM-DD'),
            dataFine: moment().format('YYYY-MM-DD'),
            oraInizio: moment().format('HH:mm'),
            oraFine: moment().add('1', 'hours').format('HH:mm'),
            sottogruppo:"personal",
            valueUtenti: [],
            optionsUtenti: [],
            idEvento: false,
            daGruppo: false
        };
    },
    beforeMount(){
        document.body.style.backgroundColor = "#f5f5f5";
        document.body.style.color = "#455A64";
        this.posAttuale= localStorage.posAttuale
        this.posPrecedente= localStorage.posPrecedente
        if (this.$route.params.idEvent) {
          this.idEvento = this.$route.params.idEvent
        }
        axios.post(store.getters.baseUrl + "event/getevento?idEvento=" + this.idEvento, {
            token: store.getters.token
        })
        .then((response) => {
          console.log(response.data)
           this.evento = response.data.evento
           this.daGruppo = response.data.evento.group_id == 0 ? false : response.data.evento.group_id
           this.nomeEvento = this.evento.title
           this.descEvento = this.evento.descrizione
           this.dataInizio = this.evento.date_start
           this.dataFine = this.evento.date_end
           this.oraInizio = this.evento.time_start
           this.oraFine = this.evento.time_end
           this.luogoEvento = this.evento.location
           if (this.daGruppo == false) {
               this.valueUtenti = this.evento.invite_user_data
               this.optionsUtenti = this.evento.invite_user_options
           }
           this.sottogruppo = this.evento.type == 0 ? 'personal' : 'business'
        })
        .catch(function (error) {
            console.log (error)
        })
    },
    methods:{
      goTo: function (url){
          router.push(url);
      },
      eliminaEvento: function () {
        swal("Sicuro di voler eliminare l'evento? (" + this.idEvento + ")", {
            buttons: {
                event_cancel: "Annulla",
                event_elimina: "Elimina"
            },
        })
        .then((value) => {
            switch (value) {
                case "event_elimina":
                    axios.post(store.getters.baseUrl + "event/delete?id=" + this.idEvento, {
                        token: store.getters.token
                    })
                    .then((response) => {
                        if (response.data.status == "KO") {
                            swal("Attenzione", response.data.message, "warning")
                        } else {
                            this.goTo("/event")
                        }
                    })
                    .catch(function () {
                        swal("Attenzione", "Errore", "warning")
                    })
                    break;
                default:
                    break;
            }
        });
        return false
      },
      modificaEvento: function(pagina){
        if(this.nomeEvento.length==0 || this.descEvento.length==0 || this.luogoEvento.length==0 || (this.valueUtenti.length == 0 && !this.daGruppo)) {
            swal("Attenzione","Inserisci tutti i campi", "warning");
            return false
        }
        console.log("pagina: ",pagina)
        console.log("sottoGruppo ",this.sottogruppo)
        console.log("utenti invitati ",this.valueUtenti)
        console.log("nome gruppo ",this.nomeEvento)
        console.log("desc gruppo ",this.descEvento)
        console.log("Data Inizio ",this.dataInizio)
        console.log("Data Fine ",this.dataFine)
        console.log("Ora Inizio ",this.oraInizio)
        console.log("Ora fine ",this.oraFine)

        var link = store.getters.baseUrl + "event/update?id=" + this.idEvento
        var datiInviare = {
          token: store.getters.token,
          title: this.nomeEvento,
          descrizione: this.descEvento,
          location: this.luogoEvento,
          date_start: this.dataInizio,
          time_start: this.oraInizio,
          date_end: this.dataFine,
          time_end: this.oraFine,
          type: this.sottogruppo === 'personal' ? 0 : 1,
          id: this.idEvento
        }
        if (this.daGruppo !== false) {
          datiInviare.group_id = this.daGruppo
        } else {
          datiInviare.invite_user = this.valueUtenti.map((elemento) => {
              return elemento.value
          }).join('-')
        }
        axios.post(link, datiInviare)
        .then((response) => {
            if (response.data.status == "OK") {
                this.goTo('/event')
            } else {
                swal("Attenzione",response.data.message, "warning");
            }
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    }
};
</script>

<style scoped>

    .corpo {
        margin-top: 7%;
    }

    .corpo h5{
        color: rgb(44, 178, 169);
        text-transform:uppercase;
        font-size: 19px;
        line-height: 31px;
        font-weight: 700;
        margin-right: 1%;
        margin-top: 2%;
    }

    p{
        color: rgb(44, 178, 169);
        font-size: 17px;
        line-height: 31px;
        font-weight: 500;
        margin-top: 2%;
    }

    input{
        color: rgb(44, 178, 169);
        
    }

    i,i:hover,i:active,i:focus{
        color: rgb(44, 178, 169);
    }

    .input-field {
        margin-bottom: 1%;
    }

    .input-field label {
        color: rgb(44, 178, 169);
        margin-top: 9px;
    }

    input,input:focus,input:active,input:hover{
        border-bottom: 1px solid rgb(44, 178, 169);
    }

    .input-field label, .input-field label:hover, .input-field label:focus, .input-field label:active  {
        font-size: 15px;
        color: rgb(44, 178, 169);
    }

    [type="textarea"]+label{
        color: rgb(44, 178, 169);
        margin-top: 15px;
    }

    input:focus{
        border-bottom: 1px solid rgb(44, 178, 169) !important;
        box-shadow: 0 1px 0 0 rgb(44, 178, 169) !important;
    }

    /* label focus color */
    .input-field input:focus + label {
    color: rgb(44, 178, 169) !important;
    }

    /* label focus color */
    .input-field input + label {
    color: rgb(44, 178, 169) !important;
    }

    /* label underline focus color */
    .input-field input:focus {
        border-bottom: 1px solid rgb(44, 178, 169);
        box-shadow: 0 1px 0 0 rgb(44, 178, 169);
    }

    /* icon prefix focus color */
    .input-field .prefix.active {
        color: rgb(44, 178, 169);
    }

    /* valid color */
    .input-field input.valid {
    border-bottom: 1px solid rgb(44, 178, 169);
    box-shadow: 0 1px 0 0 rgb(44, 178, 169);
    }

    /* invalid color */
    .input-field input.invalid {
    border-bottom: 1px solid rgb(44, 178, 169);
    box-shadow: 0 1px 0 0 rgb(44, 178, 169);
    }


    textarea.materialize-textarea,textarea.materialize-textarea:focus,textarea.materialize-textarea:active,
    textarea.materialize-textarea:hover {
        border-bottom: 1px solid rgb(44, 178, 169);
        color: rgb(44, 178, 169)!important;
        padding: 4px;
        margin-top: 21px;
        min-height:0px;
        font-size: 15px;
    }

    textarea.materialize-textarea:focus,textarea.materialize-textarea:active,
    textarea.materialize-textarea:hover {
        box-shadow: 0 1px 0 0 rgb(44, 178, 169);
    }
    .input-field textarea.materialize-textarea:focus + label {
        color: rgb(44, 178, 169) !important;
    }

    .input-field .prefix {
        margin-top: 17px;
        font-size: 23px;
    }

    .btn,.btn:active,.btn:hover,.btn:focus{
        background-color: #fff;
        color: grey;
        margin-top: 6%;
    }

    .noselezionato,.noselezionato:hover,.noselezionato:active,.noselezionato:focus{
        background-color: #fff;
        color: grey;
    }

    .noselezionatoi{
        color: grey; 
    }

    .selezionato,.selezionato:focus,.selezionato:hover,.selezionato:active{
        background-color: rgb(44, 178, 169);
        color: #fff;
    }

    .selezionatoi{
    color: #fff; 
    }

    .select {
        position: relative;
        display: flex;
        z-index: 3;
    }

    .select span,.select span:active,.select span:hover,.elect span:focus {
        background-color: rgb(44, 178, 169);
    }

    .select1 {
        z-index: 3;
    }

    .sottogruppi{
        margin-bottom: 5%;
    }

    .sottogruppi i{
        font-size: 17px;
    }
    .sottogruppi .btn{
        margin-right:5px ;
        margin-top:0;
    }

    .swal-button--event_cancel {
        background-color: white !important;
        color: black !important;
        border: 1px solid black;
    }

    @media screen and (max-width: 1024px){
        
        .corpo{
            margin-top: 17%;
        }

        .corpo h5{
            font-size: 25px;
        }

        .input-field {
            margin-top: 8%;
        }

        .input-field .prefix {
            margin-top: 9px;
            font-size: 28px;
        }

        .input-field label, .input-field label:hover, .input-field label:focus, .input-field label:active {
            margin-top: 0px;
            font-size: 22px;
        }

        .input-field  input {
            font-size: 22px;
        }

        textarea.materialize-textarea,textarea.materialize-textarea:focus,textarea.materialize-textarea:active,
        textarea.materialize-textarea:hover {
            font-size: 22px;
        }

        p {
            font-size: 21px;
            margin-top: 3%;
        }

        .sottogruppi .btn {
            margin-top: 5px;
        }

        .btn{
            margin-top: 0px;
            font-size: 20px;
            height: 48px;
        }
        
    }

    .event-data {
      text-align: center;
      font-size: 19px;
    }

    .event-data span {
      font-weight: bold;
    }

</style> 
