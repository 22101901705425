<template slot="tag" slot-scope="props">
    <div class="container-fluid">
        <div id="evento" class="corpo">
            <div class="row">
                <div class="offset-md-1 col-md-10 offset-md-1">
                    <i class="mdi mdi-chart-bar left"></i>
                    <h5>Crea un Sondaggio</h5>
                </div>
                <div class="offset-md-1 col-md-10 offset-md-1" style="display: none;">
                    <div class="sottogruppi">
                        <div :class="'waves-effect waves-light btn active '+(sottogruppo=='dataOra' ? 'selezionato': 'noselezionato') " @click="sottogruppo='dataOra'">
                            <i :class="'mdi mdi-calendar-clock '+(sottogruppo=='dataOra' ? 'selezionatoi': 'noselezionatoi')"></i>Data e ora
                        </div>
                        <div :class="'waves-effect waves-light btn '+(sottogruppo=='luogo' ? 'selezionato': 'noselezionato')" @click="sottogruppo='luogo'">
                            <i :class="'mdi mdi-map-marker '+(sottogruppo=='luogo' ? 'selezionatoi': 'noselezionatoi')"></i>Luogo
                        </div>
                    </div>
                </div>
                <div class="offset-md-1 col-md-10 offset-md-1">
                    <div class="input-field col s12 ">
                        <i  class="mdi mdi mdi-chart-bar prefix"></i>
                        <input type="text" @click="nomeSondaggio=''" v-model="nomeSondaggio">
                        <label :class="nomeSondaggio ? 'active' : ''">Titolo Sondaggio</label>
                    </div>
                    <p>Ora indica le scelte a cui gli invitati dovranno rispondere</p>

                    <div id="opzioni">
                        <p>Opzione sondaggio</p>
                        <p v-for="(n) in opzioneSondaggio " :key="n" >- {{n}}</p>
                    </div>
                    <div class="input-field col s12 ">
                        <i @click="aggiungi()" class="mdi mdi-plus-circle-outline prefix"></i>
                        <input type="text" v-model="valore">
                        <label>
                            Digita nuova opzione per il sondaggio
                        </label>
                    </div>
                     <div class="input-field col s12">
                        <i class="mdi mdi-clock-in prefix"></i>
                        <input type="date" id="birth" name="birth" data-date-format="DD-MMMM-YYYY" v-model="dataFine">
                        <label class="active">Data scadenza</label>
                    </div>
                    <div class="input-field col s12">
                        <i class="mdi mdi-clock-in prefix"></i>
                        <input type="time" id="birth"  name="birth" data-date-format="hh:mm" v-model="oraFine">
                        <label class="active">Orario scadenza</label>
                    </div>
                    <div class="col s12 select">
                        <p>Invita:</p>
                        <multiselect 
                            v-model="valueUtenti" 
                            :options="optionsUtenti" 
                            :multiple="true" 
                            :close-on-select="false"
                            :max-height="150"
                            :height="0"
                            open-direction="bottom"
                            label="name" 
                            track-by="name"
                            placeholder="Utenti">
                        </multiselect>
                    </div>

                    <div class="col s12">
                      <br><br><br>
                      <br><br><br>
                      <br><br><br>
                    </div>
                </div>
            </div>
        </div>
        <CreaNavBar :pagina="posAttuale" :paginaPrecedente="posPrecedente" @metodo="creaSondaggio"/>
    </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios'
import router from '../router'
import store from '../store'
import CreaNavBar from '../Components/CreaNavBar.vue'
import swal from 'sweetalert'
import Multiselect from 'vue-multiselect'
  
export default {
  name: "CreaSondaggio",
  components: {Multiselect,CreaNavBar},
  data: function () {
        return {
            
            info:null,
            posAttuale:"",
            posPrecedente:"",
            nomeSondaggio:"",
            valore:"",
            opzioneSondaggio:[],
            dataFine:moment().format('YYYY-MM-DD'),
            oraFine:moment().format('HH:mm'),
            sottogruppo:"dataOra",
            optionsUtenti: [],
            valueUtenti: [],
            modifica: false,
            datiDaInviare: false
        };
    },
    beforeMount(){
        document.body.style.backgroundColor = "#f5f5f5";
        document.body.style.color = "#455A64";
        this.posAttuale= localStorage.posAttuale
        this.posPrecedente= localStorage.posPrecedente
        axios.post("user/getusers", {
            token: store.getters.token
        })
        .then((response) => {
            this.optionsUtenti = response.data.utenti.map((elemento) => {
                return {
                    name: elemento.first_name + " " + elemento.last_name,
                    value: elemento.id
                }
            })
        })
        .catch(function (error) {
            console.log (error)
        })
        if (this.$route.params.idSondaggio) {
          this.modifica = this.$route.params.idSondaggio
          axios.post("poll/getpoll?id="+this.modifica, {
            token: store.getters.token
          })
          .then((response) => {
            this.info = response.data.poll
            this.valueUtenti = response.data.user.map((item) => {
              return {
                value: item.id,
                name: item.first_name + " " + item.last_name
              }
            })
            this.opzioneSondaggio = response.data.poll_options.map((item) => {
              return item.titolo
            })
            if (this.info.tipo == "0") {
              this.sottogruppo = "dataOra"
            } else {
              this.sottogruppo = "luogo"
            }
            this.nomeSondaggio = this.info.titolo
            this.dataFine = this.info.data_scadenza
            this.oraFine = this.info.ora_scadenza
          })
          .catch(function (error) {
              console.log (error)
          })
        }
    },
    methods:{
        goTo: function (url){
            router.push(url);
        },
        aggiungi:function(){
            if(this.valore!=""){
                this.opzioneSondaggio.push(this.valore)
                this.valore=""
            }
        },
        creaSondaggio: function(){
            if(this.nomeSondaggio.length==0 || this.opzioneSondaggio.length==0){
                swal("Attenzione","Inserisci tutti i campi", "warning");
                return false
            }
            if (this.modifica) {
              this.datiDaInviare = {
                token: store.getters.token,
                titolo: this.nomeSondaggio,
                tipo: this.sottoGruppo == 'dataOra' ? 0 : 1,
                scadenza_data: this.dataFine,
                scadenza_ora: this.oraFine,
                opzioni: this.opzioneSondaggio,
                invite_user: this.valueUtenti.map((elemento) => {
                  return elemento.value
                }).join('-')
              }
              this.link = "poll/update" + "?id=" + this.modifica
            } else {
              this.datiDaInviare = {
                token: store.getters.token,
                titolo: this.nomeSondaggio,
                tipo: this.sottoGruppo == 'dataOra' ? 0 : 1,
                scadenza_data: this.dataFine,
                scadenza_ora: this.oraFine,
                opzioni: this.opzioneSondaggio,
                invite_user: this.valueUtenti.map((elemento) => {
                  return elemento.value
                }).join('-')
              }
              this.link = "poll/create"
            }
              axios.post(this.link,this.datiDaInviare)
              .then((response) => {
                this.info = response.data
                this.goTo('/event')
              })
              .catch(function (error) {
                console.log(error);
              })
        }
    }
};
</script>

<style scoped>

    .corpo {
        margin-top: 7%;
    }

    .corpo h5{
        color: rgb(44, 178, 169);
        text-transform:uppercase;
        font-size: 19px;
        line-height: 31px;
        font-weight: 700;
        margin-right: 1%;
        margin-top: 2%;
        display: inline;
    }

    p{
        color: rgb(44, 178, 169);
        font-size: 17px;
        line-height: 31px;
        font-weight: 500;
        margin-top: 2%;
        margin-left: 8px;
    }

    input{
        color: rgb(44, 178, 169);
    }

    .left{
        color: rgb(44, 178, 169);
        font-size:18px;
        margin: 0 17px ;
    }

    i,i:hover,i:active,i:focus{
        color: rgb(44, 178, 169);
    }

    .input-field {
        margin-bottom: 1%;
    }

    .input-field label {
        color: rgb(44, 178, 169);
        margin-top: 9px;
    }

    input,input:focus,input:active,input:hover{
        border-bottom: 1px solid rgb(44, 178, 169);
    }

    .input-field label, .input-field label:hover, .input-field label:focus, .input-field label:active  {
        font-size: 15px;
        color: rgb(44, 178, 169);
    }

    [type="textarea"]+label{
        color: rgb(44, 178, 169);
        margin-top: 15px;
    }

    input:focus{
        border-bottom: 1px solid rgb(44, 178, 169) !important;
        box-shadow: 0 1px 0 0 rgb(44, 178, 169) !important;
    }

    /* label focus color */
    .input-field input:focus + label {
        color: rgb(44, 178, 169) !important;
    }

    /* label focus color */
    .input-field input + label {
        color: rgb(44, 178, 169) !important;
    }

    /* label underline focus color */
    .input-field input:focus {
        border-bottom: 1px solid rgb(44, 178, 169);
        box-shadow: 0 1px 0 0 rgb(44, 178, 169);
    }
    /* icon prefix focus color */
    .input-field .prefix.active {
        color: rgb(44, 178, 169);
    }

    /* valid color */
    .input-field input.valid {
        border-bottom: 1px solid rgb(44, 178, 169);
        box-shadow: 0 1px 0 0 rgb(44, 178, 169);
    }
    /* invalid color */
    .input-field input.invalid {
        border-bottom: 1px solid rgb(44, 178, 169);
        box-shadow: 0 1px 0 0 rgb(44, 178, 169);
    }

    textarea.materialize-textarea,textarea.materialize-textarea:focus,textarea.materialize-textarea:active,
    textarea.materialize-textarea:hover {
        border-bottom: 1px solid rgb(44, 178, 169);
        color: rgb(44, 178, 169)!important;
        padding: 4px;
        font-size: 15px;
        margin-top: 21px;
        min-height:0px;
    }

    textarea.materialize-textarea:focus,textarea.materialize-textarea:active,
    textarea.materialize-textarea:hover {
        box-shadow: 0 1px 0 0 rgb(44, 178, 169);
    }

    .input-field textarea.materialize-textarea:focus + label {
        color: rgb(44, 178, 169) !important;
    }

    .input-field .prefix {
        margin-top: 17px;
        font-size: 23px;
    }

    .btn,.btn:active,.btn:hover,.btn:focus{
        background-color: rgb(44, 178, 169);
        color: #fff;
        margin: 2%;
    }

    .btn i {
        font-size: 19px;
        margin: -3px 4px;
        color: #fff;
    }

    .select {
        position: relative;
        display: flex;
        z-index: 2;
    }
    .select span,.select span:active,.select span:hover,.elect span:focus {
        background-color: rgb(44, 178, 169);
    }
    .select1 {
        z-index: 3;
    }

    .sottogruppi{
        text-align: center;
        margin-top: 16px;
    }

    .sottogruppi i{
        color: #fff;
        font-size: 17px;
    }
    .sottogruppi .btn{
        margin-right:5px ;
    }

    #opzioni p{
        color: rgb(44, 178, 169);
        font-size: 17px;
        margin-top: 5%;
        margin-left: 8px;
        line-height: 0;
        font-weight: 400;

    }

    #opzioni{
        margin-left: 4%;
    }

    .noselezionato,.noselezionato:hover,.noselezionato:active,.noselezionato:focus{
        background-color: #fff;
        color: grey;
    }

    .noselezionatoi{
    color: grey !important; 
    }
    .selezionato,.selezionato:focus,.selezionato:hover,.selezionato:active{
        background-color: rgb(44, 178, 169);
        color: #fff;
    }
    .selezionatoi{
        color: #fff; 
    }

    @media screen and (max-width: 1024px){
        
        .corpo{
            margin-top: 17%;
        }

        .corpo h5{
            font-size: 25px;
        }

        #opzioni p{
            font-size: 22px;
        }

        .input-field {
            margin-top: 8%;
        }

        .input-field .prefix {
            margin-top: 9px;
            font-size: 28px;
        }

        .input-field label, .input-field label:hover, .input-field label:focus, .input-field label:active {
            margin-top: 0px;
            font-size: 22px;
        }

        .input-field  input {
            font-size: 22px;
        }

        textarea.materialize-textarea,textarea.materialize-textarea:focus,textarea.materialize-textarea:active,
        textarea.materialize-textarea:hover {
            font-size: 22px;
        }

        p {
            font-size: 21px;
            margin-top: 3%;
        }

        .sottogruppi .btn {
            margin-top: 7px;
        }

        .sottogruppi i{
            font-size: 21px;
        }

        .btn{
            margin-top: 0px;
            font-size: 20px;
            height: 48px;
        }
        
    }

</style>
