<template>
    <div class="container-fluid" v-show="$store.state.loggedIn && visualizzaCrea.includes(pagina)">
      <div class="col-md-12">
        
          <div id="main-footer-menu" class="footer-menu activeFooter">
              <ul>
                  <li>
                      <a class="nonroot"> 
                      <i class="mdi mdi-delete" @click="trashCallback()"></i>
                      </a>
                  </li>

                  <li >
                      <center>
                        <div @click="azione" id="action-btn" :class="'z-depth-1 action-btn '+ classeSfondo">
                            <i class="mdi mdi-check" ></i>
                        </div>
                      </center>
                  </li>

                  <li>
                      <a  @click="goTo(paginaPrecedente)" class="nonroot">
                          <i class="mdi mdi-window-close"></i>
                      </a>
                  </li>
              </ul>
          </div>
      </div>
    </div>
</template>
<script>
import router from '../router'

export default {
  name: "CreaNavBar",
  data: function () {
      return {
          open: true,
          nascondimenu: false,
          classeSfondo: false,
          classeColore: false,
          tendina: false,
          visualizzaCrea: ['creaEvent', 'creaSondaggio', 'creaGruppo', 'creaTask', 'showTask', 'showEvent'],
          bodyMarginEvent: ['event', 'modifica', 'profilo'],
          createEvent: ['event', 'modifica', 'profilo'],
          createPoll: ['event', 'task', 'modifica', 'profilo'],
          createGroup: ['group', 'modifica', 'profilo'],
          blu: ['chat'],
          giallo: ['task', 'showTask', 'creaTask'],
          rosso: ['group', 'profilo', 'modifica', 'creaGruppo'],
          verde: ['event', 'sondaggi', 'creaEvent', 'creaSondaggio', 'showEvent']
      };
  },
  props:{
      pagina: String,
      paginaPrecedente: String,
      trashCallback: Function
  },
  watch: {
      pagina (val) {
        this.posAttuale = val
        this.pagina = this.posAttuale
        console.log("pagina: ",this.pagina)
       
        switch(true){
            case this.blu.includes(this.pagina):
                this.classeSfondo="bluSfondo"
                this.classeColore="blu"
                break
            case this.giallo.includes(this.pagina):
                this.classeSfondo="gialloSfondo"
                this.classeColore="giallo"
                break
            case this.verde.includes(this.pagina):
                this.classeSfondo="verdeSfondo"
                this.classeColore="verde"
                break
            case this.rosso.includes(this.pagina):
                this.classeSfondo="rossoSfondo"
                this.classeColore="rosso"
                break
            default:
                this.visibile=false
                break
        }
        localStorage.setItem("classeSfondo", this.classeSfondo)
         localStorage.setItem("classeColore", this.classeColore)
         localStorage.setItem("posAttuale", this.posAttuale)
      },
      paginaPrecedente(val){
          this.paginaPrecedente= val
          console.log("precedente: ",this.paginaPrecedente)
      }
  },
  beforeMount(){
        //console.log("token chat : ",store.getters.token)
        if (localStorage.classeSfondo) {
            this.classeSfondo = localStorage.classeSfondo
        }
        if (localStorage.classeColore) {
            this.classeColore = localStorage.classeColore
        }
        if (localStorage.pagina) {
            this.pagina = localStorage.pagina
        }
        //localStorage.removeItem("classeSfondo")
    },
  methods:{
    goTo: function (url){
        this.open=true
        console.log (this.paginaPrecedente)
        switch (this.paginaPrecedente) {
            case "viewGruppo":
                var path='/viewGruppo/' + localStorage.idGruppo
                router.push(path);
                break;
            default:
                router.push('/' + url);
                break;
        }
    },
   azione: function () {
       this.$emit('metodo', this.pagina)
   }
  }
}
</script>
<style scoped>

.menu-filter {
    display: grid;
    position: fixed;
    top: 0;
    left: -3px;
    width: 100%;
    color: gray;
    background-color: white;
    border-radius: 24px;
    padding: 100px 40px 40px 40px;
    grid-template-columns: auto auto 2rem;
    grid-template-rows: repeat(2, auto);
    grid-row-gap: 24px;
    z-index: 2;
    margin: 4px;
    text-transform: uppercase;
}

.menu-filter i{
    font-size: 22px;
}
.nascondi{
    background-color: transparent !important;
}

.activeFooter{
    background-color: #f5f5f5 !important;
}

.nonroot{
    color:#455A64 !important
}
.footer-menu{
    display: block;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 60px;
    z-index: 2;
}

.action-btn{
    position: absolute;
    height: 56px;
    width: 56px;
    border: 8px solid aliceblue;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    top: -14px;
    left: 48%;
    z-index: 2;
    cursor:pointer;
}

.action-btn i{
    font-size: 19px;
}
.action-more-menu {
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: row;
    bottom: 0;
    z-index: 2;
    height: 50px;
}

.footer-menu ul {
    display: inline-block;
    width: 100%;
    padding: 7px 0px 0 0px;
    margin: 0px;
}
.footer-menu li {
    width: 33%;
    display: inline-block;
    height: 100%;
    text-align: center;
    cursor:pointer;
    
}
.footer-menu li i, .footer-menu li span{
    display: block;
    font-size: 35px;
}

.footer-menu li span{
    display: block;
    font-size: 13px;
}

.footer-menu a{

    text-decoration: none;
}

.overlay-bodyMargin{
    top: 67%;
}

.overlay-bodyMarginEvent{
    top: 62%;
}
.overlay-body{
    background-color: white;
    text-align: left;
    position: fixed;
    z-index: 2;
    padding: 18px;
    width: 260px;
    border-radius: 16px;
    margin-bottom: 12px;
    left: 41%;
    padding: 24px 0px 24px 24px;
}
.z-depth-1{
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%)
}

#action-list li, #action-list li a {
    color: #546E7A !important;
    font-size: 18px;
    padding: 2px 3px;
    text-decoration: none;
}
</style>
