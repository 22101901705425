<template>
    <div class="container-fluid">
        <div v-if="info.utente" class="corpo">
            <div class="row">
                <div class="offset-md-1 col-md-5 ">
                    <div>
                        <img :src="imgProfilo" alt="" class="centercover">
                    </div>
                    <div id="action-list" class="card-panel">
                        <div class="count">
                            <h4>{{info.groups}}</h4>
                            <p>Gruppi creati</p>
                        </div>
                        <div class="count" >
                            <h4>{{info.invited}}</h4>
                            <p>Gruppi condivisi</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                  <div class="row">
                    <div class="col-md-12">
                      <h3 class="name">{{info.utente.first_name}} {{info.utente.last_name}}</h3>
                    </div>
                    <div class="col-md-6">
                      <div class="info">
                        <h5 class="colore">Professione</h5>
                        <p>{{info.utente.profession}}</p>
                        <h5 class="colore">Descrizione</h5>
                        <p>{{info.utente.description}}</p>
                        <a id="modifica"  @click="goTo('/modifica')" class="waves-effect waves-light btn">Modifica</a>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="info">
                        <h5 class="colore">Sesso</h5>
                        <p>{{info.utente.gender}}</p>
                        <h5 class="colore">Data Nascita</h5>
                        <p>{{itDate(info.utente.birth)}}</p>
                      </div>
                    </div>
                  </div>

                  <hr>

                  <div class="row">
                    <div class="col">
                      <h4>Invita un amico</h4>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-7 input-field">
                      <input type="email" placeholder="Email" />
                    </div>
                    <div class="col-md-5 input-field">
                      <button class="waves-effect waves-light btn">Invita</button>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script scoped>
import axios from 'axios'
import router from '../router'
import store from '../store'
import utils from '../utils'

export default {
  name: "Profilo",
  data: function () {
    return {
      imgProfilo: null,
      info:       {}
    };
  },
  beforeMount () {
    utils.setBgColor("#f5f5f5", "#455A64");

    axios.post("user/getinfo", { token: store.getters.token })
      .then((response) => {
        this.info = response.data
        //this.imgProfilo = store.getters.baseUrl + 'user/userimage?image=' + this.info.utente.picture
        this.imgProfilo = store.getters.baseUrl + this.info.utente.picture
      })
      .catch(function (error) {
        console.log(error);
      })
  },
  methods:{
    goTo (url) {
      router.push(url);
    },
    itDate (engDate) {
      return utils.dateEngToIt(engDate);
    }
  }
};
</script>
<style scoped>
.corpo {
    margin-top:8% ;
    font-size: 16px;
}

img {
    margin-left: 31%;
    height: 300px;
    width: 323px;
    transition: box-shadow .25s, -webkit-box-shadow .25s;
    box-shadow: 0 5px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}

#profilo .info {
    margin-top: 1%;
}
#profilo h5 ,h3, a {
    text-transform:uppercase;
}

#profilo h5, h3, h4 {
    font-size: 20px;
    font-weight: 700;
    text-transform:uppercase;
}

#profilo .colore {
    color: #ff5555;
}

.btn, .btn:active, .btn:hover, .btn:focus {
    background-color: #ff5555;
    color: #fff;
}

.infoContatti {
    border: 1px solid transparent;
    text-align: center;
    background-color: #ff5555;
    width: 323px;
    border-radius: 51px
}

#action-list{
    background-color: #ff5555;
    position: relative;
    width: 53%;
    border-radius: 2px;
    padding: 6px;
    margin: 12px 0px 0px 185px;
    display: inline-block;
    box-shadow: none;
 }

#action-list .count {
    width: 50%;
    text-align: center;
    display: inline-block;
}
#action-list h4 , #action-list p {
    margin-top: 5%;
    color: #fff;
    text-align: center;
}

.card-panel {
    transition: box-shadow .25s, -webkit-box-shadow .25s;
    box-shadow: 0 5px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}

@media screen and (max-width: 1024px){

    .corpo {
        margin-top: 16%;
        font-size: 23px;
    }

    #profilo h5, h3, h4 {
        font-size: 22px;
    }

    img {
        margin-left:8px;
        margin-top: 1%;
        height: 380px;
        width: 380px;
    }

    #action-list{
        width: 95%;
        margin: 12px 0px 0px 3px;
    }

    .btn, .btn:active, .btn:hover, .btn:focus {
        font-size: 18px;
    }
    
}

@media screen and (max-width: 768px) {

    .corpo {
        margin-top: 19%;
        font-size: 21px;
    }

    img {
        margin-left:0px;
        margin-top: 3%;
        height: 280px;
        width: 280px;
    }

    #profilo h5, h3, h4 {
        font-size: 21px;
    }

    #action-list {
        width: 95%;
        margin: 12px 0px 0px 0px;
    }

    .btn, .btn:active, .btn:hover, .btn:focus {
        font-size: 16px;
    }

}
</style>
