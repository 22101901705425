<template slot="tag" slot-scope="props">
    <div class="container-fluid">
        <div id="gropu" class="corpo">
            <div class="row">
                <div class="offset-md-1 col-md-10 offset-md-1">
                    <h5>{{ nomeGruppo ? 'Modifica' : 'Crea' }} un Gruppo</h5>
                </div>
                <div class="offset-md-1 col-md-10 offset-md-1">
                    <div class="input-field col s12 ">
                        <i class="mdi mdi-calendar prefix primary-text"></i>
                        <input type="text" v-model="nomeGruppo">
                        <label :class="nomeGruppo ? 'active' : ''">Nome Gruppo</label>
                    </div>
               
                    <div class="input-field col s12">
                        <i class="mdi mdi-calendar-text prefix primary-text"></i>
                        <textarea  class="materialize-textarea" v-model="descGruppo"></textarea>
                        <label :class="descGruppo ? 'active' : ''">Descrizione Gruppo</label>
                    </div>
                    <div class="col s12 select">
                        <p>Invita:</p>
                        <multiselect 
                          v-model="value" 
                          :options="options" 
                          :multiple="true" 
                          :close-on-select="false"
                          :max-height="150"
                          :height="0"
                          open-direction="bottom"
                          label="name" 
                          track-by="name"
                          placeholder="Utenti">
                            
                        </multiselect>
                    </div>
                    <div v-if="mostraTipo" class="col s12 sottogruppi">
                        <p>Tipologia gruppo:</p>
                        <div :class="'waves-effect waves-light btn ' + (sottogruppo=='personal' ? 'selezionato': 'noselezionato') "  @click="sottogruppo='personal'">
                            <i :class="'mdi mdi-heart-outline left ' + (sottogruppo=='personal' ? 'selezionatoi': 'noselezionatoi')"></i>Personale
                        </div>
                        <div :class="'waves-effect waves-light btn '+(sottogruppo=='business' ? 'selezionato': 'noselezionato') " @click="sottogruppo='business'">
                            <i :class="'mdi mdi-briefcase-outline left ' + (sottogruppo=='business' ? 'selezionatoi': 'noselezionatoi')"></i>Business
                        </div>
                    </div>
                </div>
            </div>
            <CreaNavBar :pagina="posAttuale" :paginaPrecedente="posPrecedente" @metodo="creaGruppo" :trashCallback="eliminaGruppo" />
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import router from '../router'
import store from '../store'
import CreaNavBar from '../Components/CreaNavBar.vue'
import Multiselect from 'vue-multiselect'
import swal from 'sweetalert'
  
export default {
  name: "CreaGruppo",
  components: { Multiselect,CreaNavBar },
  data: function () {
    return {
      info:          null,
      nomeGruppo:    "",
      descGruppo:    "",
      posAttuale:    "",
      posPrecedente: "",
      sottogruppo:   "personal",
      mostraTipo:    true,
      value:         [],
      options:       [],
      redirect:      false,
      modifica:      false,
      link:          false,
      datiInviare:   false
    };
  },
  beforeMount(){
      if (this.$route.params.idSottoGruppo) {
        if (!this.$route.params.idSottoGruppo.includes('s')) {
          axios.post("group/getgruppo" + "?id=" + this.$route.params.idSottoGruppo, {
            token: store.getters.token
            })
             .then((response) => {
              this.info = response.data.gruppo
              this.nomeGruppo = this.info.titolo
              this.descGruppo = this.info.descrizione
              this.value = response.data.invitati.map((item) => {
                return {
                  value: item.id,
                  name: item.first_name + " " + item.last_name
                }
              })
              this.sottogruppo = this.info.type == "1" ? "business" : "personal"
              this.modifica = this.$route.params.idSottoGruppo
            })
            .catch(function (error) {
              console.log(error);
            })
        } else {
            axios.post("group/getgruppo" + "?id=" + this.$route.params.idSottoGruppo.replace("s", ""), {
                token: store.getters.token
            })
            .then((response) => {
                this.sottogruppo = response.data.gruppo.type == "1" ? "business" : "personal"
                this.mostraTipo = false
            })
        }
      }
      
      document.body.style.backgroundColor = "#f5f5f5";
      document.body.style.color = "#455A64";
      this.posAttuale= localStorage.posAttuale
      this.posPrecedente= localStorage.posPrecedente
      axios.post("user/getusers" + ((this.$route.params.idSottoGruppo && this.$route.params.idSottoGruppo.includes('s')) ? '?idGruppo=' + this.$route.params.idSottoGruppo.replace("s", "") : ''), {
        token: store.getters.token
      })
      .then((response) => {
        this.options = response.data.utenti.map((elemento) => {
          return {
            name: elemento.first_name + " " + elemento.last_name,
            value: elemento.id
          }
        })
      })
      .catch(function (error) {
          console.log (error)
      })
  },
  methods:{
    goTo: function (url) {
      router.push(url);
    },
    creaGruppo: function () {

      if(!this.nomeGruppo || !this.descGruppo || !this.value) {
        swal("Attenzione","Inserisci tutti i campi", "warning");
        return false
      }

      if (this.modifica !== false) {
        this.link = "group/update?id=" + this.modifica
        this.datiInviare = {
            token: store.getters.token,
            titolo: this.nomeGruppo,
            descrizione: this.descGruppo,
            user_invited: this.value.map((elemento) => {
                return elemento.value
            }).join('-'),
            type: (this.sottogruppo == 'business' ? 1 : 0)
        }
        this.redirect = '/viewGruppo/' + this.modifica
        
      } else {
        this.link = "group/create"
        this.datiInviare = {
            token: store.getters.token,
            titolo: this.nomeGruppo,
            descrizione: this.descGruppo,
            user_invited: this.value.map((elemento) => {
                return elemento.value
            }).join('-'),
            type: (this.sottogruppo == 'business' ? 1 : 0)
        }
        if (this.$route.params.idSottoGruppo) {
          if (this.$route.params.idSottoGruppo.includes('s')) {
            this.datiInviare.id_main_group = this.$route.params.idSottoGruppo.replace('s', '')
            this.redirect = '/viewGruppo/' + this.datiInviare.id_main_group
          }
        }
      }
      axios.post(this.link,this.datiInviare)
      .then((response) => {
        this.info = response.data
        this.goTo('/group')
      })
      .catch(function (error) {
        console.log(error);
      })
    },
    
    eliminaGruppo: function () {
        if (this.modifica !== false) {
            swal("Sicuro di voler eliminare il gruppo? (" + this.modifica + ")", {
                buttons: {
                    group_cancel: "Annulla",
                    group_elimina: "Elimina"
                },
            })
            .then((value) => {
                switch (value) {
                    case "group_elimina":
                        axios.post(store.getters.baseUrl + "group/delete?id=" + this.modifica, {
                            token: store.getters.token
                        })
                        .then((response) => {
                            if (response.data.status == "KO") {
                                swal("Attenzione", response.data.message, "warning")
                            } else {
                                this.goTo("/group")
                            }
                        })
                        .catch(function () {
                            swal("Attenzione", "Errore", "warning")
                        })
                        break;
                    default:
                        break;
                }
            });
            return false
        } else {
            router.push('/group')
        }
    }
  }
};
</script>

<style scoped>

    .corpo {
        margin-top: 7%;
    }

    .corpo h5{
        color: rgb(255, 85, 85);
        text-transform:uppercase;
        font-size: 19px;
        line-height: 31px;
        font-weight: 700;
        margin-right: 1%;
        margin-top: 2%;
    }

    p{
        color: rgb(255, 85, 85);
        font-size: 17px;
        line-height: 31px;
        font-weight: 500;
        margin-top: 2%;
    }

    input{
        color: rgb(255, 85, 85); 
    }

    i,i:hover,i:active,i:focus{
        color: rgb(255, 85, 85);
    }

    .input-field {
        margin-bottom: 1%;
    }

    .input-field label {
        color: rgb(255, 85, 85);
        margin-top: 9px;
    }

    input,input:focus,input:active,input:hover{
        border-bottom: 1px solid rgb(255, 85, 85);
    }

    .input-field label, .input-field label:hover, .input-field label:focus, .input-field label:active  {
        font-size: 15px;
        color: rgb(255, 85, 85);
    }

    [type="textarea"]+label{
        color: rgb(255, 85, 85);
        margin-top: 15px;
    }

    input:focus{
        border-bottom: 1px solid rgb(255, 85, 85) !important;
        box-shadow: 0 1px 0 0 rgb(255, 85, 85) !important;
    }

    /* label focus color */
    .input-field input:focus + label {
        color: rgb(255, 85, 85) !important;
    }

    /* label focus color */
    .input-field input + label {
        color: rgb(255, 85, 85) !important;
    }

    /* label underline focus color */
    .input-field input:focus {
        border-bottom: 1px solid rgb(255, 85, 85);
        box-shadow: 0 1px 0 0 rgb(255, 85, 85);
    }

    /* icon prefix focus color */
    .input-field .prefix.active {
        color: rgb(255, 85, 85);
    }

    /* valid color */
    .input-field input.valid {
        border-bottom: 1px solid rgb(255, 85, 85);
        box-shadow: 0 1px 0 0 rgb(255, 85, 85);
    }
    /* invalid color */
    .input-field input.invalid {
        border-bottom: 1px solid rgb(255, 85, 85);
        box-shadow: 0 1px 0 0 rgb(255, 85, 85);
    }


    textarea.materialize-textarea,textarea.materialize-textarea:focus,textarea.materialize-textarea:active,
    textarea.materialize-textarea:hover {
        border-bottom: 1px solid rgb(255, 85, 85);
        color: rgb(255, 85, 85) !important;
        padding: 4px;
        font-size: 15px;
        margin-top: 21px;
        min-height:0px;
    }

    textarea.materialize-textarea:focus,textarea.materialize-textarea:active,
    textarea.materialize-textarea:hover {
        box-shadow: 0 1px 0 0 rgb(255, 85, 85);
    }

    .input-field textarea.materialize-textarea:focus + label {
        color: rgb(255, 85, 85) !important;
    }

    .input-field .prefix {
        margin-top: 17px;
        font-size: 23px;
    }

    .btn,.btn:active,.btn:hover,.btn:focus{
        background-color: rgb(255, 85, 85);
        color: #fff;
        margin-top: 6%;
    }

    .select {
        position: relative;
        display: flex;
        z-index: 3;
    }

    .select span,.select span:active,.select span:hover,.select span:focus {
        background-color: rgb(255, 85, 85);
    }

    .sottogruppi{
        margin-bottom: 5%;
    }

    .sottogruppi i{
        color: #fff;
        font-size: 17px;
    }

    .sottogruppi .btn{
        margin-right:5px ;
        margin-top:0;
    }

    .noselezionato,.noselezionato:hover,.noselezionato:active,.noselezionato:focus{
        background-color: #fff;
        color: grey;
    }

    .noselezionatoi{
        color: grey !important; 
    }

    .selezionato,.selezionato:focus,.selezionato:hover,.selezionato:active{
        background-color: rgb(255, 85, 85);
        color: #fff;
    }

    .selezionatoi{
        color: #fff; 
    }

    @media screen and (max-width: 1024px){
        
        .corpo{
            margin-top: 17%;
        }

        .corpo h5{
            font-size: 25px;
        }

        .input-field {
            margin-top: 8%;
        }

        .input-field .prefix {
            margin-top: 9px;
            font-size: 28px;
        }

        .input-field label, .input-field label:hover, .input-field label:focus, .input-field label:active {
            margin-top: 0px;
            font-size: 22px;
        }

        .input-field  input {
            font-size: 22px;
        }

        textarea.materialize-textarea,textarea.materialize-textarea:focus,textarea.materialize-textarea:active,
        textarea.materialize-textarea:hover {
            font-size: 22px;
        }

        p {
            font-size: 21px;
            margin-top: 3%;
        }

        .sottogruppi .btn {
            margin-top: 5px;
        }

        .btn{
            margin-top: 0px;
            font-size: 20px;
            height: 48px;
        }
        
    }

</style>
