<template>
  <div class="container-fluid">
    <div class="corpo">
         <div class="row">
            <div class="offset-md-2 col-md-8 offset-md-2">
                <center v-if="!info.length">
                  <h3 >Crea il tuo primo gruppo per iniziare</h3>
                </center>
                <div v-for="item in info " :key="item.id"  class="card personal creato-da-me" @click="modificaGruppo(item.id)">
                    <div class="card-content">
                        <span class="card-title primary-text rosso">{{item.titolo}}
                            <div class="right ico-container rosso">
                                <i  v-if="item.type==0" class="mdi mdi-heart-outline"></i>
                                <i  v-if="item.type==1"  class="mdi mdi-briefcase-outline"></i>
                                <span class="group-nbr">{{item.user_invited.split("-").length}}</span>
                            </div>
                        </span>
                        <p>{{item.descrizione}}</p>
                        <div class="right container-freccia rosso">
                            <i class="mdi mdi-chevron-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script scoped>
import axios from 'axios'
import router from '../router'
import store from '../store'
import utils from '../utils'

export default {
  name: "Group",
  data: function () {
    return {
      email: "",
      psw: "",
      info: [],
      infoBase: [],
      root: "",
      open: true,
      nascondimenu: false
    };
  },
  beforeMount () {
    utils.setBgColor("#f5f5f5", "#455A64");

    axios.post("group/get", {
      token: store.getters.token
    })
    .then((response) => {
      this.info = response.data.gruppi
      this.infoBase = response.data.gruppi
    })
    .catch(function (error) {
      console.log(error);
    })

    store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case 'filter': {
          let param = ['me', 'other'].includes(state.filter.param) ? 'owner' : 'natura';

          if (state.filter.param == 'all') {
            param = 'all';
          }

          axios.post("group/get?" + param + '=' + state.filter.param, {
            token: store.getters.token
          })
          .then((response) => {
            this.info = response.data.gruppi
            this.infoBase = response.data.gruppi
          })

          break;
        }
        case 'searchGroup':
          this.info = this.infoBase.filter((item) => {
            console.log(item.titolo.toLowerCase().indexOf(state.searchGroup.q.toLowerCase()))
            return item.titolo.toLowerCase().indexOf(state.searchGroup.q.toLowerCase()) !== -1
          })
          break;
      }
    });
  },
  methods:{
      goTo: function (url){
          router.push(url);
      },
      modificaGruppo: function(id){
        router.push('/viewGruppo/' + id)
      },
  }
};
</script>

<style scoped>

    .card {
        margin-top: 2%;
        border-radius: 2px;
        cursor:pointer;
        box-shadow: none;
    }

    .primary-text{
        line-height: 48px;
        font-weight: 500;
    }


    .ico-container i, .group-nbr{
        font-size: 20px;
    }

    .group-nbr{
    color: #455A64; 
    font-size: 19px; 
    margin-left: 1px;
    font-weight: 300;
    }

    .container-freccia i{
        font-size: 20px;
    }
   

    @media screen and (max-width: 1024px)
    {
        .container-freccia i{
            font-size: 27px;
        }

        .card .card-content p {
            font-size: 21px;
        }

        .ico-container i{
            font-size: 23px;
        }

        .group-nbr{
            font-size: 22px;
        }
    }

    @media screen and (max-width: 768px) {

        .ico-container i{
            font-size: 22px;
        }

        .group-nbr{
            font-size: 21px;
        }

        .card .card-content p {
            font-size: 20px;
        }

        .container-freccia i{
            font-size: 25px;
        }
    }

</style>


